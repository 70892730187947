import { Box, Typography } from "@enerbit/base";
import React from "react";
import DayItem from "./DayItem";

const days = [
	{
		text: "Lunes",
		value: "monday",
	},
	{
		text: "Martes",
		value: "tuesday",
	},
	{
		text: "Miércoles",
		value: "wednesday",
	},
	{
		text: "Jueves",
		value: "thursday",
	},
	{
		text: "Viernes",
		value: "friday",
	},
	{
		text: "Sábado",
		value: "saturday",
	},
	{
		text: "Domingo",
		value: "sunday",
	},
];

const SchedulesTeam = () => {
	return (
		<Box sx={{ paddingLeft: "24px", width: "100%" }}>
			<Typography color="primary" variant="h6" my={2}>
				Horario semanal
			</Typography>
			{days.map((day) => (
				<DayItem key={day.value} day={day} />
			))}
		</Box>
	);
};

export default SchedulesTeam;
