import {
	Box,
	Button,
	Grid,
	RemoveRedEyeOutlinedIcon,
	Typography,
	enerbitColors,
	useSnackbar,
} from "@enerbit/base";
import { type FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";
import { loadFile, uploadFile } from "../../../services";

export const ImageForm: FC<IDynamicForm> = ({ name, label }) => {
	const [previewImage, setPreviewImage] = useState("");
	const [openPreviewImage, setOpenPreviewImage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState<number>(0);

	const { enqueueSnackbar } = useSnackbar();
	const { watch, setValue } = useFormContext();

	const handleOpenPreviewImage = async () => {
		await loadFile(watch(name ?? ""))
			.then((image) => {
				setPreviewImage(image);
				setOpenPreviewImage(true);
			})
			.catch(() =>
				enqueueSnackbar("No se pudo cargar la imagen.", { variant: "error" }),
			);
	};
	const handleClosePreviewImage = () => setOpenPreviewImage(false);

	const handleImageChange = async (file: File) => {
		if (file) {
			try {
				setIsLoading(true);
				setProgress(0);
				const imageUrl = await uploadFile(file, (event: ProgressEvent) => {
					const percentCompleted = Math.round(
						(event.loaded * 100) / event.total,
					);
					setProgress(percentCompleted);
				});
				setValue(name!, imageUrl);
				setIsLoading(false);
			} catch (error) {
				console.error("Error uploading image:", error);
			}
		}
	};

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			// Llama a la función que maneja el archivo aceptado
			handleImageChange(acceptedFiles[0]);
		},
		[handleImageChange],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"image/jpeg": [],
			"image/png": [],
		},
	});

	return (
		<Grid item xs={12}>
			{watch(name!) || (!watch(name!) && isLoading) ? (
				<Box width="100%" className="box-certification-edit">
					<Box width={"100%"} p={"16px 32px"}>
						<Grid container spacing={2}>
							<Grid item xs={6} xl={8} display="flex" alignItems="center">
								<Typography
									sx={{ fontWeight: "bold", color: enerbitColors.primary.main }}
								>
									{label}
								</Typography>
							</Grid>
							<Grid
								container
								xs={6}
								xl={4}
								item
								spacing={2}
								display="flex"
								justifyContent="end"
							>
								<Grid item>
									<Button
										variant="contained"
										startIcon={<RemoveRedEyeOutlinedIcon />}
										className="edit-button"
										onClick={handleOpenPreviewImage}
									>
										Ver foto
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Box>
			) : (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography
							sx={{ fontWeight: "bold", color: enerbitColors.primary.main }}
						>
							{label}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box
							{...getRootProps()}
							className="dropzone"
							sx={{
								border: "2px dashed #cccccc",
								borderRadius: "12px",
								p: 4,
								textAlign: "center",
								cursor: "pointer",
								backgroundColor: isDragActive ? "#eeeeee" : "",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								gap: 2,
							}}
						>
							<input {...getInputProps()} />
							<Typography>
								{isDragActive ? "Suelta el archivo aquí" : "Arrastra aquí o"}
							</Typography>
							<Button variant="contained" sx={{ mt: 2 }}>
								Adjunte el archivo
							</Button>
						</Box>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
