import { z } from "zod";
import { finalDateValidation, initialDateValidation } from "../utils";

export const schemaOrder = z
	.object({
		status: z.string(),
		work_group_id: z.string().nullable(),
		priority: z.string().nullable(),
		id: z.string(),
		planned_date_begin: z
			.string({ invalid_type_error: "Este campo es requerido." })
			.datetime()
			.superRefine((value, ctx) => {
				initialDateValidation(value, ctx);
			}),
		planned_date_end: z
			.string({ invalid_type_error: "Este campo es requerido." })
			.datetime()
			.superRefine((value, ctx) => {
				initialDateValidation(value, ctx);
			}),
		state: z.string().min(1, "Este campo es requerido."),
		city: z.string().min(1, "Este campo es requerido."),
		form_data: z.object({
			description: z.string().min(1, "Este campo es requerido."),
			criteria: z
				.object({
					cancellation_date_xm: z.string().nullable(),
					coordinations: z.array(z.string()),
				})
				.nullable(),
		}),
		order_reason: z
			.string()
			.nullable()
			.refine((val) => (val !== null && val !== undefined ? !!val : true), {
				message: "Este campo es requerido.",
			}),
	})
	.superRefine((data, ctx) => {
		const begin = new Date(data.planned_date_begin);
		const end = new Date(data.planned_date_end);
		finalDateValidation(begin, end, ctx);
	});
