import { enerbitColors } from "@enerbit/base";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Skeleton,
} from "@mui/material";
import type { FC } from "react";

export const TeamItemSkeleton: FC = () => {
	return (
		<Grid item xs={12} md={6} lg={4} xl={3}>
			<Card
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					border: `1px solid ${enerbitColors.neutral[300]}`,
					borderRadius: "14px",
					height: "100%",
				}}
			>
				<CardHeader
					title={<Skeleton animation="wave" height={20} width="80%" />}
				/>
				<CardContent>
					<Box display={"flex"} justifyContent={"space-between"} mb={1}>
						<Skeleton animation="wave" height={15} width="50%" />
						<Skeleton
							animation="wave"
							variant="circular"
							width={25}
							height={25}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							justifyContent: "space-between",
						}}
					>
						<Box
							display="flex"
							justifyContent="space-around"
							alignItems="center"
							gap={1}
						>
							{Array.from(new Array(4)).map((_, index) => (
								<Skeleton
									key={index.toString()}
									animation="wave"
									variant="rectangular"
									width={80}
									height={30}
								/>
							))}
						</Box>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							gap={1}
							mt={2}
						>
							{Array.from(new Array(7)).map((_, index) => (
								<Skeleton
									key={index.toString()}
									variant="rectangular"
									animation="wave"
									height={30}
									width={30}
								/>
							))}
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Grid>
	);
};
