import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	type SxProps,
	Typography,
	decodedJwt,
	enerbitColors,
} from "@enerbit/base";
import type { EventProps } from "react-big-calendar";
import { useOrderStore } from "../../store";
import { coordination } from "../../utils";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import type { IEventOrder } from "../../interfaces";

export const CustomEvent = ({ title, event }: EventProps) => {
	const orderEvent = event as IEventOrder;

	const { view } = useOrderStore();

	const availableCoordinations =
		coordination.find(
			({ code }) => code === orderEvent?.resource.order_type.code,
		)?.coordinations ?? [];

	const selectedCoordinations =
		orderEvent?.resource.form_data.criteria?.coordinations ?? [];

	const eventStyles: SxProps = {
		display: "flex",
		flexDirection: "column",
	};

	const decodedToken = decodedJwt();

	const role = decodedToken?.role;

	return (
		<Box sx={eventStyles}>
			{role !== "contratista" && view !== "month" && (
				<Box sx={{ display: "flex" }}>
					<Box
						sx={{
							borderRadius: "4px",
							p: 0.25,
							backgroundColor:
								orderEvent.resource.status === "pending"
									? enerbitColors.success.main
									: enerbitColors.neutral.main,
						}}
					>
						<Typography fontSize={"10px"} fontWeight={"bold"} color={"white"}>
							{orderEvent.resource.status === "pending"
								? "Para ejecución"
								: "En preparación"}
						</Typography>
					</Box>
				</Box>
			)}
			<Typography
				sx={{
					fontWeight: 700,
					fontSize: view !== "month" ? "16px" : "12px",
				}}
			>
				{title}
			</Typography>
			{view !== "month" && (
				<>
					{orderEvent.resource.order_entities.length > 0 && (
						<Box>
							<Typography
								color="primary"
								sx={{ fontSize: "14px" }}
								fontWeight={"bold"}
							>
								Información clientes
							</Typography>
							<List dense>
								{orderEvent.resource.order_entities.map((entity, index) => {
									return (
										<ListItem disablePadding key={index.toString()}>
											<ListItemButton>
												<ListItemText
													primary={
														<Box key={index.toString()}>
															<Box
																sx={{
																	display: "flex",
																	gap: 2,
																	placeItems: "center",
																	width: "100%",
																}}
															>
																<Typography
																	variant="body1"
																	fontWeight={"bold"}
																	sx={{ fontSize: "12px" }}
																>
																	{entity.pii.full_name}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: 2,
																	width: "100%",
																	fontSize: "12px",
																}}
															>
																<Typography variant="body1">
																	{" "}
																	{entity.pii.address}
																</Typography>
															</Box>
														</Box>
													}
												/>
											</ListItemButton>
										</ListItem>
									);
								})}
							</List>
						</Box>
					)}

					{role !== "contratista" && (
						<Box sx={{ mt: 0.5 }}>
							<Typography
								color="primary"
								sx={{ fontSize: "14px" }}
								fontWeight={"bold"}
							>
								Coordinaciones
							</Typography>
							{availableCoordinations.map((coordination, index) => (
								<Box
									key={index.toString()}
									sx={{ display: "flex", alignItems: "center" }}
								>
									{selectedCoordinations.includes(
										coordination.value.toString(),
									) ? (
										<CheckCircleIcon
											sx={{ color: enerbitColors.success.main, padding: 0.5 }}
										/>
									) : (
										<RadioButtonUncheckedIcon
											sx={{ color: enerbitColors.neutral.main, padding: 0.5 }}
										/>
									)}
									<Typography sx={{ fontSize: "12px" }}>
										{coordination.text}
									</Typography>
								</Box>
							))}
						</Box>
					)}
				</>
			)}
		</Box>
	);
};
