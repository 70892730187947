import {
	Box,
	CloseIcon,
	ContentCopyIcon,
	EditIcon,
	Grid,
	IconButton,
	Tooltip,
	Typography,
	useSnackbar,
} from "@enerbit/base";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { StyledDialog } from "../../common";
import type { IDay } from "../../interfaces";
import { formatDate } from "../../utils/date";
import DialogItem from "./DialogItem";

interface Props {
	day: IDay;
}

const DayItem = ({ day }: Props) => {
	const [isOpenDialog, setIsOpenDialog] = useState(false);

	const { watch, setValue } = useFormContext();
	const { enqueueSnackbar } = useSnackbar();
	const sinceName = `schedules.week.${day.value}.0.since`;
	const untilName = `schedules.week.${day.value}.0.until`;

	const sinceNameOver = `schedules.week.${day.value}.1.since`;
	const untilNameOver = `schedules.week.${day.value}.1.until`;

	const isActiveCopy = watch("isActiveCopy");

	const since = watch(sinceName);
	const until = watch(untilName);
	const sinceOver = watch(sinceNameOver);
	const untilOver = watch(untilNameOver);

	const onCloseDialog = () => {
		setIsOpenDialog(false);
	};

	const onCopyHour = (selectedDay: string) => {
		const currentCopyHour = watch(`schedules.week.${selectedDay}.0`);
		setValue("currentHour", currentCopyHour);
		setValue("isActiveCopy", true);
		enqueueSnackbar("Horario copiado correctamente.", {
			variant: "success",
		});
	};

	const onPasteHour = () => {
		const currentHour = watch("currentHour");
		if (currentHour) {
			setValue(`schedules.week.${day.value}.0`, currentHour);
			enqueueSnackbar("Horario Pegado correctamente.", {
				variant: "success",
			});
		}
	};

	const onRemoveHour = () => {
		setValue(`schedules.week.${day.value}`, null);
	};

	useEffect(() => {}, [watch(`schedules.week.${day.value}`)]);

	return (
		<>
			<Grid container spacing={3} sx={{ my: 3 }}>
				<Grid item xs={3}>
					<Typography variant="h6" fontWeight="bold">
						{day.text}
					</Typography>
				</Grid>

				<Grid item xs={6} textAlign={"end"}>
					{since && until && !isOpenDialog ? (
						<>
							<Typography color="neutral.main" fontSize={"medium"}>
								{formatDate(since)} - {formatDate(until)}
							</Typography>
							{sinceOver && untilOver && !isOpenDialog && (
								<Box
									sx={{
										borderRadius: "8px",
										backgroundColor: "info.100",
										p: 0.5,
										pr: 2,
									}}
								>
									<Typography color="info.main">
										Horario extraordinario:{" "}
										<b>
											{formatDate(sinceOver)} - {formatDate(untilOver)}
										</b>
									</Typography>
								</Box>
							)}
						</>
					) : (
						<Typography color="secondary">Sin horario</Typography>
					)}
				</Grid>

				<Grid item xs={3} alignItems={"center"} justifyItems="center">
					<Tooltip title="Copiar horario">
						<IconButton
							id={`copy-${day.value}`}
							size="small"
							disabled={!since || !until}
							onClick={() => onCopyHour(day.value)}
						>
							<ContentCopyIcon fontSize="small" />
						</IconButton>
					</Tooltip>

					<Tooltip title="Pegar horario">
						<IconButton
							size="small"
							id={`paste-${day.value}`}
							onClick={onPasteHour}
							disabled={!isActiveCopy}
						>
							<ContentPasteIcon fontSize="small" />
						</IconButton>
					</Tooltip>

					<Tooltip title="Eliminar horario">
						<IconButton
							id={`delete-${day.value}`}
							size="small"
							onClick={onRemoveHour}
							disabled={!since || !until}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Tooltip>

					<Tooltip title="Editar horario">
						<IconButton
							id={`edit-${day.value}`}
							size="small"
							onClick={() => setIsOpenDialog(true)}
						>
							<EditIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<StyledDialog open={isOpenDialog} onClose={onCloseDialog}>
				<DialogItem
					key={day.value}
					day={day}
					onClose={onCloseDialog}
					sinceName={sinceName}
					untilName={untilName}
					sinceNameOver={sinceNameOver}
					untilNameOver={untilNameOver}
				/>
			</StyledDialog>
		</>
	);
};

export default DayItem;
