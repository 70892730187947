import { Box, Grid, Skeleton } from "@mui/material";
import type { FC } from "react";

const BestTeamItemSkeleton: FC = () => {
	return (
		<Grid item xs={4}>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					border: "1px solid #e0e0e0",
					borderRadius: "14px",
					height: "100%",
					padding: "16px",
					gap: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Skeleton variant="text" width={120} height={30} />
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						gap: 1,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mt: "auto",
							gap: 2,
						}}
					>
						<Skeleton variant="text" width={80} height={20} />
						<Skeleton variant="circular" width={25} height={25} />
						<Skeleton variant="circular" width={25} height={25} />
						<Skeleton variant="circular" width={25} height={25} />
					</Box>
				</Box>
				<Skeleton variant="rectangular" width="100%" height={40} />
			</Box>
		</Grid>
	);
};

export default BestTeamItemSkeleton;
