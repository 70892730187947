// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-header {
  font-weight: bold;
  text-align: center;
  background-color: #53358e!important;
  color: white!important;
}

.rbc-day-slot .rbc-event{
  border:1px solid #667085!important
}

.rbc-agenda-date-cell{
  background-color: #FFF!important;
  color:  #667085!important;
}

.rbc-header.rbc-today{
  background-color: #53358e!important;
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
  margin-bottom: 20px;
}

.rbc-time-header {
  height: 0px;
}

.rbc-time-slot {
  flex: none;
}

.rbc-today:not(.rbc-header) {
  background-color: #eeebf4;
}

.rbc-current-time-indicator {
  background-color: #53358e!important;
  height: 3px!important;
}

.rbc-month-row {
  overflow: unset;
}

.rbc-show-more {
  color: #53358e!important;
}

.rbc-show-more:hover {
  color: #53358e;
}

.rbc-event:focus {
  outline: 5px auto #53358e;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Crono.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;EACnC,sBAAsB;AACxB;;AAEA;EACE;AACF;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mCAAmC;EACnC,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".rbc-header {\n  font-weight: bold;\n  text-align: center;\n  background-color: #53358e!important;\n  color: white!important;\n}\n\n.rbc-day-slot .rbc-event{\n  border:1px solid #667085!important\n}\n\n.rbc-agenda-date-cell{\n  background-color: #FFF!important;\n  color:  #667085!important;\n}\n\n.rbc-header.rbc-today{\n  background-color: #53358e!important;\n}\n\n.rbc-time-header.rbc-overflowing {\n  border-right: none;\n  margin-bottom: 20px;\n}\n\n.rbc-time-header {\n  height: 0px;\n}\n\n.rbc-time-slot {\n  flex: none;\n}\n\n.rbc-today:not(.rbc-header) {\n  background-color: #eeebf4;\n}\n\n.rbc-current-time-indicator {\n  background-color: #53358e!important;\n  height: 3px!important;\n}\n\n.rbc-month-row {\n  overflow: unset;\n}\n\n.rbc-show-more {\n  color: #53358e!important;\n}\n\n.rbc-show-more:hover {\n  color: #53358e;\n}\n\n.rbc-event:focus {\n  outline: 5px auto #53358e;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
