import { z } from "zod";

const PiiOperatorSchema = z.object({
	full_name: z.string(),
	legal_id_type: z.string(),
	legal_id_code: z.string(),
	phone: z.string(),
	email: z.string(),
	country: z.string(),
	state: z.string(),
	city: z.string(),
	app_operator_id: z.string(),
	form_data: z.any().nullable(),
	status: z.string(),
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string().nullable(),
});

const RolSchema = z.object({
	name: z.string(),
	code: z.string(),
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string().nullable(),
});

const TokenSchema = z.object({
	token: z.string().optional(),
	app_operator_id: z.string(),
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string().nullable(),
	topic_id: z.string().optional(),
});

const CompanySchema = z.object({
	name: z.string(),
	legal_id_code: z.string(),
	address: z.string(),
	phone: z.string(),
	email: z.string(),
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string().nullable(),
});

export const UserItemSchema = z.object({
	username: z.string(),
	password: z.string().optional(),
	rol_id: z.string().optional(),
	company_id: z.string().optional(),
	id: z.string(),
	pii: PiiOperatorSchema,
	rol: RolSchema,
	token: TokenSchema,
	topics: z.array(TokenSchema),
	company: CompanySchema,
	created_at: z.string(),
	updated_at: z.string().nullable(),
});
