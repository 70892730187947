import {
	AdapterDayjs,
	Box,
	Button,
	ButtonGroup,
	DateCalendar,
	type Dayjs,
	KeyboardArrowRightIcon,
	LocalizationProvider,
	Menu,
	type SxProps,
	Typography,
	dayjs,
} from "@enerbit/base";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { type MouseEvent, useState } from "react";
import type { ToolbarProps, View } from "react-big-calendar";
import { useOrderStore } from "../../store";

import "dayjs/locale/es";
import { getStartDate, getStartEndConcat } from "../../utils";

const VIEW_OPTIONS: { label: string; view: View }[] = [
	{ label: "Día", view: "day" },
	{ label: "Semana", view: "agenda" },
	{ label: "Mes", view: "month" },
];

const CustomToolbar = ({ date, view, onView, onNavigate }: ToolbarProps) => {
	const { loadingOrders: loading, setCurrentDate } = useOrderStore();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [newDate, setNewDate] = useState<Dayjs>(dayjs());

	const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const navToPrevious = () => {
		onNavigate("PREV");
	};

	const navToNext = () => {
		onNavigate("NEXT");
	};

	const todayButtonsStyles: SxProps = {
		borderRadius: "20px !important",
		height: "40px",
		border: "2px solid #F2F4F7 !important",
		boxShadow: "0px 2px 5px 0px rgba(38, 51, 77, 0.03)",
		color: "#667085",
	};

	const navigationButtonsStyles: SxProps = {
		border: "2px solid #F2F4F7 !important",
		borderRadius: "50% !important",
		height: "40px !important",
		width: "40px !important",
		minWidth: "unset !important",
		boxShadow: "0px 2px 5px 0px rgba(38, 51, 77, 0.03)",
		color: "#667085",
	};

	const viewButtonsStyles: SxProps = {
		height: "40px !important",
		border: "2px solid #F2F4F7 !important",
	};

	const handleSelect = (date: Dayjs) => {
		setNewDate(date);
		onNavigate("DATE", date.toDate());
		onView("day");
		handleCloseMenu();
	};

	const handleView = (date: Date, view: View) => {
		const newDate = getStartDate(date, view);
		setCurrentDate(newDate);
		onNavigate("DATE", new Date(newDate));
		onView(view);
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				pb: 3,
			}}
		>
			<Box sx={{ display: "flex", gap: 1 }}>
				<Button
					id="today"
					disabled={loading}
					variant="outlined"
					sx={todayButtonsStyles}
					onClick={() => onNavigate("TODAY")}
				>
					Hoy
				</Button>
				<Button
					id="calendar"
					disabled={loading}
					variant="outlined"
					sx={todayButtonsStyles}
					onClick={handleOpenMenu}
				>
					<CalendarMonthIcon />
				</Button>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleCloseMenu}
						anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
						transformOrigin={{ vertical: "top", horizontal: "left" }}
						MenuListProps={{ "aria-labelledby": "basic-button" }}
					>
						<DateCalendar value={newDate} onChange={handleSelect} />
					</Menu>
				</LocalizationProvider>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: 2,
				}}
			>
				<Button
					id="prev"
					disabled={loading}
					sx={navigationButtonsStyles}
					onClick={navToPrevious}
				>
					<KeyboardArrowLeftIcon />
				</Button>
				<Typography
					sx={{ fontSize: "12px", fontWeight: 700, color: "#667085" }}
				>
					{getStartEndConcat(date, view)}
				</Typography>
				<Button
					id="next"
					disabled={loading}
					sx={navigationButtonsStyles}
					onClick={navToNext}
				>
					<KeyboardArrowRightIcon />
				</Button>
			</Box>
			<ButtonGroup variant="outlined">
				{VIEW_OPTIONS.map((item, index) => (
					<Button
						id={item.view}
						disabled={loading}
						key={index.toString()}
						sx={{
							...viewButtonsStyles,
							color: item.view === view ? "#667085" : "#E4E7EC",
							borderRadius:
								index === 0
									? "30px 0px 0px 30px !important"
									: index === VIEW_OPTIONS.length - 1
										? "0px 30px 30px 0px !important"
										: "unset !important",
						}}
						onClick={() => handleView(date, item.view)}
					>
						{item.label}
					</Button>
				))}
			</ButtonGroup>
		</Box>
	);
};

export default CustomToolbar;
