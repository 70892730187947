import { api } from "@enerbit/base";
import type { IGetOrders } from "../interfaces";

export const getOrdersPage = async (
	page: number,
	size: number,
	startDate: string,
	endDate: string,
	state?: string,
	city?: string,
	work_group_id?: string,
	status?: string,
	company_id?: string,
): Promise<IGetOrders> => {
	const currentStatus = status ? status : "pending_assignment,pending";

	const { data } = await api.get<IGetOrders>(
		`/service-orders/orders?sort_planned_date_begin=DESC&status=${currentStatus}&page=${page}&size=${size}&planned_date_begin=${startDate}&planned_date_end=${endDate}${state ? `&state=${state}` : ""}${city ? `&city=${city}` : ""}${company_id ? `&assigned_to_company_id=${company_id}` : ""}`,
	);
	return data;
};
