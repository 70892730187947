import { FormControl, TimePicker, Typography, dayjs } from "@enerbit/base";

import { get, useFormContext } from "react-hook-form";

interface Props {
	name: string;
	label: string;
}

const DateItem = ({ name, label }: Props) => {
	const {
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	const handleTimeChange = (time: dayjs.Dayjs | null, name: string) => {
		if (name) {
			if (time) {
				setValue(name, time);
			} else {
				setValue(name, null);
			}
		}
	};

	const value = watch(name);

	return (
		<FormControl fullWidth>
			<Typography color="neutral.500" sx={{ my: 1 }}>
				{label}
			</Typography>
			<TimePicker
				ampm
				value={
					typeof value === "string"
						? dayjs(value, "HH:mm:ss").tz("America/Bogota")
						: value
							? value.tz("America/Bogota")
							: null
				}
				disableOpenPicker
				sx={{ "& fieldset": { borderRadius: "14px" } }}
				views={["hours", "minutes"]}
				onChange={(value) => handleTimeChange(value, name)}
				slotProps={{
					textField: {
						error: !!get(errors, name),
						helperText: !!get(errors, name) && get(errors, name)?.message,
						id: name,
					},
				}}
			/>
		</FormControl>
	);
};

export default DateItem;
