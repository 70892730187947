import {
	Avatar,
	Badge,
	Box,
	Button,
	Checkbox,
	FilterListIcon,
	Menu,
	MenuItem,
	enerbitColors,
} from "@enerbit/base";
import { type FC, useRef, useState } from "react";
import type { IItemsJson } from "../../interfaces";

interface DynamicFilterProps {
	id?: string;
	options: IItemsJson[];
	selected: string[];
	onSelect: (values: string[]) => void;
	label: string;
	isMultiple?: boolean;
	fullWidth?: boolean;
	disabled?: boolean;
}

export const DynamicFilter: FC<DynamicFilterProps> = ({
	id,
	options,
	selected,
	onSelect,
	label = "Seleccione",
	isMultiple = false,
	fullWidth = false,
	disabled = false,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const buttonRef = useRef<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSelection = (value: string | boolean) => {
		const stringValue = typeof value === "boolean" ? value.toString() : value;
		let newSelected = [...selected];

		if (isMultiple) {
			if (newSelected.includes(stringValue)) {
				newSelected = newSelected.filter((item) => item !== stringValue);
			} else {
				newSelected.push(stringValue);
			}
		} else {
			newSelected = newSelected.includes(stringValue) ? [] : [stringValue];
			handleClose();
		}
		onSelect(newSelected);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	let selectedLabel = label;
	if (!isMultiple && selected.length > 0) {
		selectedLabel =
			options.find((opt) => opt.value === selected[0])?.text || label;
	}

	return (
		<Box sx={{ width: "100%" }}>
			<Badge
				className={`${selected.length > 0 ? "ping" : ""}`}
				color="primary"
				badgeContent={isMultiple ? "" : undefined}
				variant="dot"
				invisible={selected.length === 0}
				sx={{ width: "100%" }}
			>
				<span className={`${selected.length > 0 ? "ping-animation" : ""}`} />
				<Button
					id={id}
					ref={buttonRef}
					disabled={disabled}
					fullWidth={fullWidth}
					sx={{
						border: "1px solid #D0D5DD",
						width: fullWidth ? "100%" : "auto",
						whiteSpace: "nowrap", // Agrega esta línea para evitar saltos de línea
					}}
					startIcon={<FilterListIcon sx={{ mt: -0.5 }} />}
					endIcon={
						isMultiple ? (
							<Avatar
								sx={{
									width: 24,
									height: 24,
									backgroundColor: enerbitColors.primary.main,
									fontFamily: "sans-serif",
								}}
							>
								{selected.length}
							</Avatar>
						) : null
					}
					onClick={handleClick}
				>
					{selectedLabel}
				</Button>
			</Badge>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				slotProps={{
					paper: {
						style: {
							minWidth: anchorEl ? anchorEl.clientWidth : "0%",
						},
					},
				}}
			>
				{options.map((option) => {
					const stringValue = option.value.toString();
					const isSelected = selected.includes(stringValue);

					return (
						<MenuItem
							key={option.text}
							onClick={() =>
								!isMultiple ? handleSelection(stringValue) : null
							}
							sx={
								isSelected
									? {
											background: !isMultiple
												? enerbitColors.primary.main
												: "white",
											color: !isMultiple ? "white" : "black",
											":hover": {
												background: !isMultiple
													? enerbitColors.primary.main
													: "white",
												color: !isMultiple ? "white" : "black",
											},
										}
									: {}
							}
						>
							{isMultiple && (
								<Checkbox
									checked={isSelected}
									onClick={() => handleSelection(stringValue)}
								/>
							)}
							{option.text}
						</MenuItem>
					);
				})}
			</Menu>
		</Box>
	);
};
