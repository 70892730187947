import {
	Avatar,
	AvatarGroup,
	Box,
	Chip,
	Divider,
	EditIcon,
	Grid,
	IconButton,
	Tooltip,
	Typography,
	capitalize,
	dayjs,
	enerbitColors,
} from "@enerbit/base";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { type FC, useMemo, useState } from "react";
import type { IDayKey, IWorkGroupsItem } from "../../interfaces";
import { stringToColor } from "../../utils";
import { UpdateTeamModal } from "./UpdateTeamModal";

dayjs.extend(customParseFormat);

interface Props {
	item: IWorkGroupsItem;
	index: string;
}

export const TeamItem: FC<Props> = ({ item, index }) => {
	const { city, state, operators, mobility, schedules } = item;

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const allMeasures = operators.flatMap(
		({ pii }) => pii?.form_data?.measurement_type_trained ?? [],
	);

	const uniqueMeasures = Array.from(new Set(allMeasures));

	const formatTime = (time: string): string => {
		const formattedTime = dayjs.utc(time, "HH:mm:ss[Z]").format("hh:mm A");
		return formattedTime;
	};

	const dayInfo = useMemo(() => {
		const info: Record<string, { active: boolean; details: string[] }> = {};
		const dayNames = {
			monday: "Lu",
			tuesday: "Ma",
			wednesday: "Mi",
			thursday: "Ju",
			friday: "Vi",
			saturday: "Sa",
			sunday: "Do",
		};

		const addInfo = (
			day: string,
			scheduleType: string,
			since: string,
			until: string,
		) => {
			const scheduleDescription =
				scheduleType === "overtime" ? "Extraordinario" : "Ordinario";
			if (!info[day]) {
				info[day] = { active: true, details: [] };
			}
			const detailString = `${scheduleDescription}: ${formatTime(
				since,
			)} - ${formatTime(until)}`;
			if (!info[day].details.includes(detailString)) {
				info[day].details.push(detailString);
			}
		};

		Object.entries({ ...schedules.week, ...(schedules.weekend ?? {}) }).forEach(
			([dayName, dayArray]) => {
				if (Array.isArray(dayArray)) {
					dayArray.forEach((day) => {
						if (day !== null) {
							addInfo(
								dayNames[dayName as keyof typeof dayNames],
								day.is_overtime ? "overtime" : "ordinary",
								day.since,
								day.until,
							);
						}
					});
				}
			},
		);

		schedules.overtime?.forEach(({ day, since, until }) => {
			const dayKey: IDayKey = day as IDayKey;
			const initial = dayNames[dayKey];
			addInfo(initial, "overtime", since, until);
		});

		return info;
	}, [schedules]);

	const renderTooltipContent = (details: string[]) => (
		<Box>
			{details.map((detail, index) => (
				<Typography key={index.toString()} component="div" color="white">
					{detail}
				</Typography>
			))}
		</Box>
	);

	const teamName = useMemo(() => {
		const namesBeforeAt = operators.map(
			({ username }) => username.split("@")[0],
		);
		const joinedNamesWithHyphen = namesBeforeAt.join("-");
		return `${joinedNamesWithHyphen}`;
	}, [operators]);

	return (
		<Grid item xs={12} md={6} lg={4} xl={3}>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					border: `1px solid ${enerbitColors.primary.main}`,
					borderRadius: "14px",
					height: "100%",
					padding: "16px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
						Cuadrilla {teamName}
					</Typography>
					<IconButton
						size="small"
						onClick={handleOpen}
						id={`edit-team-${index}`}
					>
						<EditIcon fontSize="small" />
					</IconButton>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						gap: 1,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mt: "auto",
							gap: 2,
						}}
					>
						<Box width={"75%"}>
							<Typography variant="body1">
								Departamento: <b>{state}</b>
							</Typography>

							<Typography
								variant="body1"
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								Ciudad:<b>{city}</b>
							</Typography>

							<Typography
								variant="body1"
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								Aliado:
								<b>{item.operators[0]?.company?.name || "No registra"}</b>
							</Typography>
						</Box>
						<AvatarGroup
							total={operators.length}
							max={3}
							sx={{
								".MuiAvatarGroup-avatar": {
									width: 25,
									height: 25,
									fontSize: 10,
								},
							}}
						>
							{operators.map(({ pii, username }, index) => (
								<Tooltip title={pii?.full_name} key={index.toString()}>
									<Avatar
										alt={username}
										src={pii?.form_data?.image_profile?.path_photography}
										sx={{
											width: 25,
											height: 25,
											backgroundColor: stringToColor(username),
											fontSize: 10,
										}}
									>
										{username.substring(0, 2).toUpperCase()}
									</Avatar>
								</Tooltip>
							))}
						</AvatarGroup>
					</Box>
					<Divider />
					<Box
						sx={{
							display: "flex",
							gap: 1,
							overflowX: "auto",
							whiteSpace: "nowrap",
						}}
					>
						{mobility.map((item, index) => (
							<Chip
								key={index.toString()}
								label={capitalize(item)}
								sx={{
									backgroundColor: "#E4ECF7",
									color: "#505780",
									fontWeight: "bold",
									borderRadius: "10px",
								}}
							/>
						))}
						{uniqueMeasures.map((measurement, index) => (
							<Chip
								key={index.toString()}
								label={capitalize(measurement)}
								sx={{
									backgroundColor: "#E4ECF7",
									color: "#505780",
									fontWeight: "bold",
									borderRadius: "10px",
								}}
							/>
						))}
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							justifyContent: "space-between",
							overflowX: "auto",
							whiteSpace: "nowrap",
							color: "white",
						}}
					>
						{["Lu", "Ma", "Mi", "Ju", "Vi", "Sa", "Do"].map((day, index) => (
							<Tooltip
								key={index.toString()}
								title={
									dayInfo[day]?.active
										? renderTooltipContent(dayInfo[day].details)
										: "No disponible"
								}
							>
								<Chip
									color="primary"
									variant="outlined"
									label={day}
									disabled={!dayInfo[day]?.active}
									sx={{ borderRadius: "5px" }}
								/>
							</Tooltip>
						))}
					</Box>
				</Box>
			</Box>
			<UpdateTeamModal open={open} handleClose={handleClose} team={item} />
		</Grid>
	);
};
