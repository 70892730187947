import {
	CircularProgress,
	FormControl,
	Grid,
	InputAdornment,
	TextField,
} from "@enerbit/base";
import { type ChangeEvent, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { useTeamStore } from "../../store";

export const SearchTeam = () => {
	const { setSearch, loading, debounceSearch, setDebounceSearch } =
		useTeamStore((state) => state);

	const [debouncedValue] = useDebounce(debounceSearch, 500);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setDebounceSearch(event.target.value);
	};

	useEffect(() => {
		setSearch(debouncedValue);
	}, [debouncedValue, setSearch]);

	return (
		<Grid item xs={6}>
			<FormControl fullWidth>
				<TextField
					fullWidth
					name={"search"}
					value={debounceSearch}
					onChange={handleInputChange}
					sx={{ "& fieldset": { borderRadius: "14px" } }}
					type={"text"}
					id={"team-search"}
					disabled={loading}
					variant="outlined"
					placeholder="Buscar por nombre de operador"
					InputProps={{
						endAdornment: loading && (
							<InputAdornment position="end">
								<CircularProgress size={20} thickness={6} />
							</InputAdornment>
						),
					}}
				/>
			</FormControl>
		</Grid>
	);
};
