import {
	Box,
	ExpandCircleDownOutlinedIcon,
	Grid,
	Typography,
	enerbitColors,
} from "@enerbit/base";
import { type SyntheticEvent, useState } from "react";
import {
	StyledAccordion2,
	StyledAccordionDetails2,
	StyledAccordionSummary2,
} from "../../common";
import { useSelectedOrderStore } from "../../store/selectedOrder";

export const DiagnosticViability = () => {
	const diagnostic = useSelectedOrderStore(
		(state) => state.previous_diagnostic?.form_data.diagnostic,
	);

	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChange =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	const renderTextWithDifferentColors = (
		text: string,
		{ boldBeforeColon = false, boldAfterColon = false } = {},
	) => {
		const [beforeColon, afterColon] = text.split(/:(.+)/);
		return (
			<Typography>
				<span
					style={{
						color: enerbitColors.primary.main,
						fontWeight: boldBeforeColon ? "bold" : "normal",
					}}
				>
					{beforeColon}:
				</span>
				<span
					style={{
						color: enerbitColors.neutral.main,
						fontWeight: boldAfterColon ? "bold" : "normal",
					}}
				>
					{afterColon}
				</span>
			</Typography>
		);
	};

	return (
		<Grid item xs={12}>
			<StyledAccordion2
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
			>
				<StyledAccordionSummary2
					expandIcon={
						<ExpandCircleDownOutlinedIcon
							sx={{
								fontSize: "1.5rem",
							}}
						/>
					}
				>
					<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
						<Typography
							sx={{
								fontWeight: 700,
								fontSize: "18px",
								color: "#667085",
							}}
						>
							Diagnóstico de viabilidad
						</Typography>
					</div>
				</StyledAccordionSummary2>
				<StyledAccordionDetails2>
					<Grid container spacing={3}>
						<Box px={2} pt={2}>
							{renderTextWithDifferentColors(
								`Medidor principal: ${diagnostic?.main_meter}`,
							)}
							{renderTextWithDifferentColors(
								`Bloqueo de pruebas: ${diagnostic?.test_block}`,
							)}
							{renderTextWithDifferentColors(
								`Comunicación: ${diagnostic?.comunication}`,
							)}
							{diagnostic?.current_transformer &&
								renderTextWithDifferentColors(
									`Transformador de corriente: ${diagnostic?.current_transformer}`,
								)}

							{diagnostic?.voltage_transformer &&
								renderTextWithDifferentColors(
									`Transformador de tensión: ${diagnostic?.voltage_transformer}`,
								)}
							{renderTextWithDifferentColors(
								`Paneles o cajas: ${diagnostic?.box}`,
							)}
							{renderTextWithDifferentColors(
								`Cable de control: ${diagnostic?.control_wire}`,
							)}
							{renderTextWithDifferentColors(
								`Apertura: ${diagnostic?.opening}`,
							)}
							{diagnostic?.comment_bussiness &&
								renderTextWithDifferentColors(
									`Comentarios comercial: ${diagnostic?.comment_bussiness}`,
								)}
							{diagnostic?.comment_or &&
								renderTextWithDifferentColors(
									`Comentarios para el OR: ${diagnostic?.comment_or}`,
								)}
							{diagnostic?.observation_work_order &&
								renderTextWithDifferentColors(
									`Observación para orden de servicio: ${diagnostic?.observation_work_order}`,
								)}
							{diagnostic?.reasons_update &&
								renderTextWithDifferentColors(
									`Razones de la actualización: ${diagnostic?.reasons_update}`,
								)}
							{renderTextWithDifferentColors(`Otro: ${diagnostic?.other}`, {
								boldAfterColon: true,
								boldBeforeColon: true,
							})}
						</Box>
					</Grid>
				</StyledAccordionDetails2>
			</StyledAccordion2>
		</Grid>
	);
};
