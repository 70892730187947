import { api } from "@enerbit/base";
import moment from "moment";
import type { View } from "react-big-calendar";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { IFiltersOrder, IOrderState, ItemGetOrders } from "../interfaces";
import { getOrdersPage, getTeamsByCompany } from "../services";
import { getDepartments } from "../services/getOptions";
import { getEndDate, getStartDate } from "../utils";

const updateFilter =
	(
		set: (
			partial:
				| IOrderState
				| Partial<IOrderState>
				| ((state: IOrderState) => IOrderState | Partial<IOrderState>),
			replace?: boolean | undefined,
		) => void,
	) =>
	(filterName: keyof IFiltersOrder, value: string[] | boolean) =>
		set(({ filters }) => ({
			filters: { ...filters, [filterName]: value },
		}));

export const useOrderStore = create<IOrderState>()(
	devtools(
		(set) => ({
			message: null,
			loadingUpdatedOrder: false,
			orders: [],
			view: "agenda",
			currentDate: getStartDate(new Date(), "agenda"),
			search: "",
			debounceSearch: "",
			loadingOrders: false,
			departments: [],
			filters: {
				selectedState: [],
				selectedCity: [],
				selectedStatus: [],
				setSelectedState: (options) =>
					updateFilter(set)("selectedState", options),
				setSelectedCity: (options) =>
					updateFilter(set)("selectedCity", options),
				setSelectedStatus: (options) =>
					updateFilter(set)("selectedStatus", options),
			},
			setSearch: (search) => set(() => ({ search: search })),
			setDebounceSearch: (search) => set(() => ({ search: search })),
			setDepartments: async () => {
				const departments = await getDepartments();
				set({ departments });
				return departments;
			},
			getOrders: async (currentDate, view, company_id, state, city, status) => {
				set({ loadingOrders: true, orders: [], message: null });

				const size = 5;
				let allOrders: ItemGetOrders[] = [];

				const startDate = getStartDate(new Date(currentDate), view);
				const endDate = getEndDate(new Date(currentDate), view);

				try {
					if (company_id) {
						const teams = await getTeamsByCompany(company_id);

						if (teams.length === 0) {
							set({
								message: {
									description: "No se encontraron equipos para la empresa.",
									status: "warning",
								},
							});
							set({ loadingOrders: false });
							return;
						}

						const promises = teams.map(async (team) => {
							const firstPageData = await getOrdersPage(
								0,
								size,
								startDate,
								endDate,
								state,
								city,
								team.id,
								status,
								company_id,
							);
							const totalOrders = firstPageData.total;
							let teamOrders = firstPageData.items;

							const totalPages = Math.ceil((totalOrders - size) / size);

							const pagePromises = [];
							for (let page = 1; page <= totalPages; page++) {
								pagePromises.push(
									getOrdersPage(
										page,
										size,
										startDate,
										endDate,
										state,
										city,
										team.id,
										status,
										company_id,
									),
								);
							}

							const results = await Promise.all(pagePromises);

							for (const result of results) {
								teamOrders = [...teamOrders, ...result.items];
							}

							return teamOrders;
						});

						const ordersResults = await Promise.all(promises);

						allOrders = ordersResults.flat();
					} else {
						const firstPageData = await getOrdersPage(
							0,
							size,
							startDate,
							endDate,
							state,
							city,
							undefined,
							status,
						);
						const totalOrders = firstPageData.total;
						allOrders = firstPageData.items;

						const totalPages = Math.ceil((totalOrders - size) / size);

						const promises = [];

						for (let page = 1; page <= totalPages; page++) {
							promises.push(
								getOrdersPage(
									page,
									size,
									startDate,
									endDate,
									state,
									city,
									undefined,
									status,
									company_id,
								),
							);
						}

						const results = await Promise.all(promises);

						for (const result of results) {
							allOrders = [...allOrders, ...result.items];
						}
					}

					const uniqueOrdersMap = new Map();
					for (const order of allOrders) {
						uniqueOrdersMap.set(order.id, order);
					}
					const uniqueOrders = Array.from(uniqueOrdersMap.values());

					set({
						orders: uniqueOrders.map((order) => ({
							title: order.order_type.name,
							start: new Date(order.planned_date_begin),
							end: new Date(order.planned_date_end),
							resource: {
								...order,
							},
						})),
					});
				} catch (error) {
					set({
						message: {
							description: "Ha ocurrido un error al obtener las órdenes.",
							status: "error",
						},
					});
				} finally {
					set({ loadingOrders: false });
				}
			},
			setCurrentDate: (date: string) => set({ currentDate: date }),
			setView: (view: View) => set({ view: view }),
			setOrders: (orders) => set({ orders: orders }),
			updateOrder: async (id, updateOrder, work_group_id) => {
				try {
					set({ loadingUpdatedOrder: true, message: null });
					await api.patch(`/service-orders/orders/${id}`, updateOrder);
					set((state: IOrderState) => {
						const updatedOrders = state.orders.map((order) => {
							if (order.resource.id === id) {
								return {
									...order,
									start: new Date(updateOrder.planned_date_begin),
									end: new Date(updateOrder.planned_date_end),
									resource: {
										...order.resource,
										status: updateOrder.status,
										planned_date_begin: updateOrder.planned_date_begin,
										planned_date_end: updateOrder.planned_date_end,
										form_data: {
											...order.resource.form_data,
											criteria: {
												...order.resource.form_data.criteria,
												coordinations:
													updateOrder.form_data?.criteria?.coordinations ?? [],
												cancellation_date_xm:
													updateOrder.form_data?.criteria
														?.cancellation_date_xm ?? null,
											},
										},
										order_operators: order.resource.order_operators,
										work_group_id: work_group_id,
									},
									title: order.title,
								};
							}
							return order;
						});

						return {
							orders: updatedOrders,
							message: {
								description: "Orden editada correctamente.",
								status: "success",
							},
						};
					});
				} catch (error) {
					set({
						message: {
							description: "Ha ocurrido un error al editar la orden.",
							status: "error",
						},
					});
				} finally {
					set({ loadingUpdatedOrder: false });
				}
			},
			updateDateTimeOrder: async (id, start, end) => {
				try {
					set({ message: null });
					await api.patch(`/service-orders/orders/${id}`, {
						planned_date_begin: start.toISOString(),
						planned_date_end: end.toISOString(),
					});
					set((state: IOrderState) => {
						const updatedOrders = state.orders.map((order) => {
							if (order.resource.id === id) {
								return {
									...order,
									start: start,
									end: end,
									resource: {
										...order.resource,
										planned_date_begin: start.toISOString(),
										planned_date_end: end.toISOString(),
									},
									title: order.title,
								};
							}
							return order;
						});

						return {
							orders: updatedOrders,
							message: {
								description: "Orden editada correctamente.",
								status: "success",
							},
						};
					});
				} catch (error) {
					set({
						message: {
							description: "Ha ocurrido un error al editar la orden.",
							status: "error",
						},
					});
				}
			},
		}),
		{ anonymousActionType: "order" },
	),
);
