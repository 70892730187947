import {
	AdapterDayjs,
	Box,
	Button,
	FormControlLabel,
	Grid,
	HighlightOffIcon,
	LocalizationProvider,
	Switch,
	Typography,
	enerbitColors,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import type { IDay } from "../../interfaces";
import DateItem from "./DateItem";

interface Props {
	day: IDay;
	onClose: () => void;
	sinceName: string;
	untilName: string;
	sinceNameOver: string;
	untilNameOver: string;
}

const DialogItem = ({
	day,
	onClose,
	sinceName,
	untilName,
	sinceNameOver,
	untilNameOver,
}: Props) => {
	const {
		watch,
		setValue,
		trigger,
		formState: { errors },
	} = useFormContext();
	const [isOverTime, setIsOverTime] = useState<boolean>(
		!!watch(`schedules.week.${day.value}.1`),
	);
	const [isClosing, setIsClosing] = useState(false);

	const onSaveSchedule = async () => {
		const isValid = await trigger("schedules.week");

		if (isValid) {
			onClose();
		}
	};

	const scheduleDay = watch(`schedules.week.${day.value}`);
	const overTimeSchedule = watch(`schedules.week.${day.value}.1`);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (scheduleDay === null && !isClosing)
			setValue(`schedules.week.${day.value}`, [
				{ since: null, until: null, is_overtime: false },
			]);
	}, [scheduleDay, day.value, isClosing]);

	const onCloseModal = async () => {
		const isValid = await trigger("schedules.week");
		if (isValid) {
			onClose();
		} else {
			setIsClosing(true);
			setValue(`schedules.week.${day.value}`, null);
			onClose();
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (isOverTime && !overTimeSchedule) {
			setValue(`schedules.week.${day.value}.1`, {
				since: null,
				until: null,
				is_overtime: true,
			});
		} else if (!isOverTime && overTimeSchedule) {
			const updatedSchedules = watch(`schedules.week.${day.value}`);
			updatedSchedules.splice(1, 1);
			setValue(`schedules.week.${day.value}`, updatedSchedules);
		}
	}, [isOverTime, day.value]);

	return (
		<Box sx={{ p: 4, width: "100%" }}>
			<Grid container spacing={3} alignItems={"center"}>
				<Grid item xs={6}>
					<Typography color="primary" variant="h5">
						Horario {day.text}
					</Typography>
				</Grid>
				<Grid item xs={6} textAlign={"end"}>
					<HighlightOffIcon
						sx={{ color: enerbitColors.neutral.main, cursor: "pointer" }}
						fontSize="medium"
						fontWeight="semibold"
						onClick={onCloseModal}
					/>
				</Grid>

				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<Grid item xs={6}>
						<DateItem name={sinceName} label={"Horario de entrada"} />
					</Grid>

					<Grid item xs={6}>
						<DateItem name={untilName} label={"Horario de salida"} />
					</Grid>
				</LocalizationProvider>

				<Grid item xs={6}>
					<Typography color="primary">
						¿Tiene horario extraordinario?
					</Typography>
				</Grid>
				<Grid item xs={6} textAlign={"end"}>
					<FormControlLabel
						control={
							<Switch
								color="secondary"
								onChange={({ target }) => setIsOverTime(target.checked)}
								checked={isOverTime}
							/>
						}
						label={isOverTime ? "Si" : "No"}
					/>
				</Grid>

				{isOverTime && (
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Grid item xs={6}>
							<DateItem name={sinceNameOver} label={"Horario de entrada"} />
						</Grid>

						<Grid item xs={6}>
							<DateItem name={untilNameOver} label={"Horario de salida"} />
						</Grid>
					</LocalizationProvider>
				)}

				<Button
					variant="contained"
					color="secondary"
					fullWidth
					sx={{ ml: "24px", mt: 3 }}
					onClick={onSaveSchedule}
				>
					Guardar horario
				</Button>
			</Grid>
		</Box>
	);
};

export default DialogItem;
