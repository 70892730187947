import {
	AdapterDayjs,
	DatePicker,
	type Dayjs,
	Grid,
	InputLabel,
	LocalizationProvider,
	dayjs,
} from "@enerbit/base";
import type { FC } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";
import "dayjs/locale/es";

import isToday from "dayjs/plugin/isToday";
dayjs.extend(isToday);

export const DateForm: FC<IDynamicForm> = ({ label, name, sx, props }) => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
	} = useFormContext();

	const currentValue = name && watch(name);

	const shouldDisableDate = (date: Dayjs | Date | string): boolean => {
		const dayjsDate = dayjs.isDayjs(date) ? date : dayjs(date);
		if (dayjsDate.isToday() && props?.disableToday) {
			return true;
		}
		if (props?.disableDatesBefore) {
			const disableBeforeDate = dayjs.isDayjs(props.disableDatesBefore)
				? props.disableDatesBefore
				: dayjs(props.disableDatesBefore);
			if (dayjsDate.isBefore(disableBeforeDate, "day")) {
				return true;
			}
		}

		return false;
	};

	const handleDateChange = (date: Dayjs | null) => {
		if (name) {
			const dateStr = date ? date.format("YYYY-MM-DD") : null;
			setValue(name, dateStr);
		}
	};

	if (!name) return null;

	return (
		<Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<InputLabel className="title-input-edition">{label}</InputLabel>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
				<Controller
					name={name}
					control={control}
					defaultValue={null}
					render={({ field: { value } }) => (
						<DatePicker
							key={currentValue || "empty"}
							disabled={props?.disabled}
							value={value ? dayjs(value) : null}
							format="YYYY-MM-DD"
							disablePast={props?.disablePast}
							disableFuture={props?.disableFuture}
							shouldDisableDate={shouldDisableDate}
							onChange={handleDateChange}
							slotProps={{
								textField: {
									disabled: props?.disabled,
									id: name,
									color: "primary",
									helperText: get(errors, name)
										? get(errors, name).message
										: "",
									error: !!get(errors, name),
									sx: {
										"& fieldset": { borderRadius: "14px" },
										"& p": { color: "red" },
										width: "100%",
									},
								},
							}}
						/>
					)}
				/>
			</LocalizationProvider>
		</Grid>
	);
};
