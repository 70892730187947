import { DialogContent, Typography } from "@enerbit/base";
import type { FC } from "react";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { CreateTeamForm } from "./CreateTeamForm";

interface Props {
	open: boolean;
	handleClose: () => void;
}

export const CreateTeamModal: FC<Props> = ({ open, handleClose }) => {
	return (
		<StyledDialog open={open} fullWidth>
			<StyledDialogTitle onClose={handleClose} id={"id-team-create"}>
				<Typography
					sx={{
						color: "#53358E",
						fontSize: "25px",
						lineHeight: "47px",
						letterSpacing: "-0.02em",
					}}
				>
					Crear cuadrilla
				</Typography>
			</StyledDialogTitle>
			<DialogContent>
				<CreateTeamForm handleClose={handleClose} />
			</DialogContent>
		</StyledDialog>
	);
};
