import { CachedIcon, Grid, IconButton, enerbitColors } from "@enerbit/base";
import { useEffect, useState } from "react";
import type { IItemsJson } from "../../interfaces";
import { getCities } from "../../services/getOptions";
import { useOrderStore } from "../../store";
import { DynamicFilter } from "../dynamic/DynamicFIlter";

export const FiltersOrder = () => {
	const {
		setDepartments,
		setSearch,
		setDebounceSearch,
		loadingOrders: loading,
	} = useOrderStore();

	const {
		selectedCity,
		selectedState,
		setSelectedCity,
		setSelectedState,
		selectedStatus,
		setSelectedStatus,
	} = useOrderStore((state) => state.filters);

	const [optionsStates, setOptionsStates] = useState<IItemsJson[]>([]);
	const [optionsCities, setOptionsCities] = useState<IItemsJson[]>([]);
	const [isLoadingStates, setIsLoadingStates] = useState(false);
	const [isLoadingCities, setIsLoadingCities] = useState(false);

	const handleRefresh = () => {
		setSelectedCity([]);
		setSelectedState([]);
		setSelectedStatus([]);
		setSearch("");
		setDebounceSearch("");
	};

	useEffect(() => {
		const fetchDepartments = async () => {
			setIsLoadingStates(true);
			const departments = await setDepartments();
			setIsLoadingStates(false);
			setOptionsStates(departments);
		};

		fetchDepartments();
	}, []);

	useEffect(() => {
		const fetchCities = async () => {
			if (selectedState.length > 0) {
				setIsLoadingCities(true);
				const cities = await getCities(selectedState[0]);
				setIsLoadingCities(false);
				setOptionsCities(cities);
			}
		};

		fetchCities();
	}, [selectedState]);

	return (
		<Grid
			container
			item
			xs={7}
			spacing={3}
			sx={{ display: "flex", justifyContent: "end" }}
		>
			<Grid item>
				<DynamicFilter
					id="status"
					options={[
						{
							text: "Por ejecución",
							value: "pending",
						},
						{
							text: "En preparación",
							value: "pending_assignment",
						},
					]}
					selected={selectedStatus}
					onSelect={setSelectedStatus}
					label="Estado"
					disabled={isLoadingStates || loading}
				/>
			</Grid>

			<Grid item>
				<DynamicFilter
					id="state"
					options={optionsStates}
					selected={selectedState}
					onSelect={setSelectedState}
					label="Departamento"
					disabled={isLoadingStates || loading}
				/>
			</Grid>
			{selectedState.length > 0 && (
				<Grid item>
					<DynamicFilter
						id="city"
						options={optionsCities}
						selected={selectedCity}
						onSelect={setSelectedCity}
						label="Ciudad"
						disabled={isLoadingCities || loading}
					/>
				</Grid>
			)}

			<Grid item>
				<IconButton
					id="cache"
					disabled={loading}
					onClick={handleRefresh}
					aria-label="cached"
					style={{
						color: "#fff",
						backgroundColor: enerbitColors.primary.main,
						borderRadius: "50%",
						padding: "10px",
					}}
				>
					<CachedIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};
