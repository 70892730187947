import { enerbitColors } from "@enerbit/base";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import moment from "moment";
import type {
	Event,
	EventPropGetter,
	SlotPropGetter,
	View,
} from "react-big-calendar";

export const getEventStyles: EventPropGetter<Event> = (event: Event) => {
	const style = {
		backgroundColor:
			event.resource.status === "pending"
				? enerbitColors.success[100]
				: enerbitColors.neutral[100],
		borderRadius: "4px",

		color:
			event.resource.status === "pending"
				? enerbitColors.success.main
				: enerbitColors.neutral.main,
	};
	return {
		style,
	};
};

export const slotPropGetter: SlotPropGetter = (date) => {
	const now = new Date();
	now.setSeconds(0, 0);

	if (date < now) {
		return {
			className: "rbc-past-slot",
		};
	}
	return {};
};

export const getStartDate = (currentDate: Date, view: View): string => {
	return moment(currentDate)
		.startOf(view === "agenda" ? "week" : view === "month" ? "month" : "day")
		.toISOString();
};

export const getEndDate = (currentDate: Date, view: View): string => {
	return moment(currentDate)
		.endOf(view === "agenda" ? "week" : view === "month" ? "month" : "day")
		.toISOString();
};

export const getStartEndConcat = (currentDate: Date, view: View): string => {
	const startDateISO = getStartDate(currentDate, view);
	const endDateISO = getEndDate(currentDate, view);
	const startDate = format(new Date(startDateISO), "MMMM d, yyyy", {
		locale: es,
	});

	if (view === "day") {
		return startDate;
	}

	const endDate = format(new Date(endDateISO), "MMMM d, yyyy", { locale: es });
	return `${startDate} - ${endDate}`;
};

export const agendaTimeRangeFormat = (start: Date, end: Date): string => {
	const startFormatted = format(start, "h:mm a", { locale: es });
	const endFormatted = format(end, "h:mm a", { locale: es });

	return `${startFormatted} - ${endFormatted}`;
};
