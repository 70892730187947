import { api } from "@enerbit/base";
import axios from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type {
	IFiltersTeam,
	IRespWorkGroups,
	ITeamState,
	IWorkGroupsItem,
} from "../interfaces";
import { getDepartments } from "../services";

const updateFilter =
	(
		set: (
			partial:
				| ITeamState
				| Partial<ITeamState>
				| ((state: ITeamState) => ITeamState | Partial<ITeamState>),
			replace?: boolean | undefined,
		) => void,
	) =>
	(filterName: keyof IFiltersTeam, value: string[] | boolean) =>
		set(({ filters }) => ({
			filters: { ...filters, [filterName]: value },
		}));

export const useTeamStore = create<ITeamState>()(
	devtools(
		(set) => ({
			search: "",
			debounceSearch: "",
			pagination: {
				page: 0,
				size: 12,
				total: 1,
			},
			loading: false,
			loadingTeam: false,
			loadingTeams: false,
			listTeams: [],
			departments: [],
			filters: {
				selectedState: [],
				selectedCity: [],
				selectedMeter: [],
				setSelectedState: (options) =>
					updateFilter(set)("selectedState", options),
				setSelectedCity: (options) =>
					updateFilter(set)("selectedCity", options),
				setSelectedMeter: (options) =>
					updateFilter(set)("selectedMeter", options),
			},
			setSearch: (search) => set(() => ({ search: search })),
			setDebounceSearch: (debounceSearch) =>
				set(() => ({ debounceSearch: debounceSearch })),
			setPage: (page) =>
				set((state) => ({
					pagination: {
						...state.pagination,
						page: page,
					},
				})),
			setSelectedFilter: (type, value) =>
				set((state) => ({ filters: { ...state.filters, [type]: value } })),
			createTeam: async (team) => {
				try {
					set({ loadingTeam: true });
					const { data } = await api.post<IWorkGroupsItem>(
						"/work-groups/work-groups",
						team,
					);
					set((state) => {
						const currentCount = state.listTeams.length;
						const pageSize = state.pagination.size;
						const newTotal = state.pagination.total + 1;

						if (currentCount < pageSize) {
							return {
								listTeams: [...state.listTeams, data],
								pagination: {
									...state.pagination,
									total: newTotal,
								},
							};
						}
						return {
							pagination: {
								...state.pagination,
								total: newTotal,
							},
						};
					});
				} catch (error) {
					if (axios.isAxiosError(error)) {
						if (error.response?.status === 401) {
							throw new Error("Esta cuadrilla ya existe.");
						}
						throw new Error("Error desconocido.");
					}
					throw new Error("Error desconocido.");
				} finally {
					set({ loadingTeam: false });
				}
			},
			getTeams: async (
				page,
				size,
				state,
				city,
				measurement_type_trained,
				email,
				company_id,
			) => {
				try {
					set({ loading: true });
					const { data } = await api.get<IRespWorkGroups>(
						`/work-groups/work-groups?page=${page}&size=${size}${
							state ? `&state_origin=${state}` : ""
						}${city ? `&city_origin=${city}` : ""}${
							measurement_type_trained
								? `&measurement_type_trained=${measurement_type_trained}`
								: ""
						}${email ? `&username=${email}` : ""}${company_id ? `&company_id=${company_id}` : ""}`,
					);
					set({
						listTeams: data.items,
						pagination: {
							page,
							size,
							total: data.total,
						},
					});
				} catch (error) {
					throw new Error("Error al consultar cuadrillas.");
				} finally {
					set({ loading: false });
				}
			},
			updateTeam: async (team) => {
				try {
					set({ loadingTeam: true });
					const { data } = await api.patch<IWorkGroupsItem>(
						`/work-groups/work-groups/${team.id}`,
						team,
					);
					set((state) => {
						const index = state.listTeams.findIndex(
							(item) => item.id === team.id,
						);
						if (index === -1) {
							throw new Error("Equipo no encontrado.");
						}

						const updatedTeams = [...state.listTeams];
						updatedTeams[index] = data;
						return { listTeams: updatedTeams };
					});
				} catch (error) {
					if (axios.isAxiosError(error)) {
						if (error.response?.status === 401) {
							throw new Error("Esta cuadrilla ya existe.");
						}
						throw new Error("Error desconocido.");
					}
					throw new Error("Error desconocido.");
				} finally {
					set({ loadingTeam: false });
				}
			},
			setDepartments: async () => {
				const departments = await getDepartments();
				set({ departments });
				return departments;
			},
			searchTeams: async (
				id,
				state,
				city,
				start,
				end,
				company_id,
				measurement_type,
			) => {
				try {
					set({ loadingTeams: true, loadingTeam: !!id, listTeams: [] });

					const [teamsResponse, teamResponse] = await Promise.all([
						api.get<IRespWorkGroups>(
							`/work-groups/available-work-groups?start=${start}&end=${end}${company_id ? `&company_id=${company_id}` : ""}&state_coverage=${state}&city_coverage=${city}${measurement_type ? `&measurement_type_trained=${measurement_type}` : ""}`,
						),
						id
							? api.get<IWorkGroupsItem>(`/work-groups/work-groups/${id}`)
							: Promise.resolve(null),
					]);

					const teams = teamsResponse.data.items.filter(
						(team) => team.id !== id,
					);

					const selectedTeam = id && teamResponse ? teamResponse.data : null;

					if (selectedTeam) {
						teams.unshift(selectedTeam);
					}

					set({
						loadingTeams: false,
						loadingTeam: false,
						listTeams: teams,
						// listTeams: teams.slice(0, 3),
					});
				} catch (error) {
					set({ loadingTeams: false, loadingTeam: false });
				}
			},
			resetListTeams: () => set({ listTeams: [] }),
		}),
		{ anonymousActionType: "team" },
	),
);
