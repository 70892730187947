import { DialogContent, Typography } from "@enerbit/base";
import { type FC, useMemo } from "react";
import { UpdateTeamForm } from ".";
import { StyledDialog, StyledDialogTitle } from "../../common";
import type { IWorkGroupsItem } from "../../interfaces";

interface Props {
	open: boolean;
	handleClose: () => void;
	team: IWorkGroupsItem;
}

export const UpdateTeamModal: FC<Props> = ({ open, handleClose, team }) => {
	const { operators } = team;

	const teamName = useMemo(() => {
		const namesBeforeAt = operators.map(
			({ username }) => username.split("@")[0],
		);
		const joinedNamesWithHyphen = namesBeforeAt.join("-");
		return `${joinedNamesWithHyphen}`;
	}, [operators]);

	return (
		<StyledDialog open={open} fullWidth maxWidth="lg">
			<StyledDialogTitle onClose={handleClose} id={"id-team-update"}>
				<Typography
					sx={{
						color: "#53358E",
						fontSize: "25px",
						lineHeight: "47px",
						letterSpacing: "-0.02em",
					}}
				>
					Actualizar cuadrilla {teamName}
				</Typography>
			</StyledDialogTitle>
			<DialogContent>
				<UpdateTeamForm team={team} handleClose={handleClose} />
			</DialogContent>
		</StyledDialog>
	);
};
