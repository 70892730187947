import { api } from "@enerbit/base";

export const uploadFile = async (
	image: File,
	onUploadProgress: (progressEvent: ProgressEvent) => void,
) => {
	const bodyFormData = new FormData();
	bodyFormData.append("file", image);

	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress: (progressEvent: any) => {
			// Convierte el evento de Axios a un formato estándar de ProgressEvent
			const standardProgressEvent = {
				...progressEvent,
				total: progressEvent.total,
				loaded: progressEvent.loaded,
			};

			onUploadProgress(standardProgressEvent);
		},
		transformRequest: () => {
			return bodyFormData;
		},
	};

	const { data } = await api.put<{ file_path: string }>(
		`/service-orders-certificates/upload-file`,
		bodyFormData,
		config,
	);

	return data.file_path;
};

export const loadFile = async (url_image: string) => {
	return await api
		.get<{ url: string }>(
			`/service-orders-certificates/authenticate-file?url_path=${url_image}`,
		)
		.then(({ data }) => data.url);
};
