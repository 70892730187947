import { HashRouter, Route, Routes } from "react-router-dom";
import { Crono, Team } from "../pages";

const Router = () => {
	return (
		<HashRouter basename="/">
			<Routes>
				<Route path="/crono" element={<Crono />} />
				<Route path="/crono/teams" element={<Team />} />
			</Routes>
		</HashRouter>
	);
};

export default Router;
