import { Grid, Typography } from "@enerbit/base";
import type { FC } from "react";
import type { IDynamicForm } from "../../../interfaces";

export const TitleForm: FC<IDynamicForm> = ({ label }) => {
	return (
		<Grid item xs={12}>
			<Typography color="primary" sx={{ fontWeight: 700, fontSize: "18px" }}>
				{label}
			</Typography>
		</Grid>
	);
};
