import {
	Autocomplete,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	TextField,
} from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type { IDynamicForm, IItemsJson } from "../../../interfaces";

export const AutocompleteForm: FC<IDynamicForm> = ({
	name,
	label,
	fetchItems,
	dependencyField,
	sx,
}) => {
	const {
		control,
		watch,
		resetField,
		formState: { errors },
	} = useFormContext();
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<IItemsJson[]>([]);
	const dependentValue = dependencyField ? watch(dependencyField) : "";
	const loading = open && options.length === 0;

	const [key, setKey] = useState(0);

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			if (fetchItems && active) {
				const fetchedItems = dependencyField
					? await fetchItems(dependentValue)
					: await fetchItems();
				setOptions(fetchedItems);
			}
		})();

		return () => {
			active = false;
		};
	}, [loading, fetchItems, dependentValue, dependencyField]);

	useEffect(() => {
		if (dependencyField && name) {
			resetField(name);
			setKey((prevKey) => prevKey + 1);
		}
	}, [dependentValue, dependencyField, name]);

	useEffect(() => {
		if (name && !watch(name) && !dependencyField) {
			resetField(name);
			setKey((prevKey) => prevKey + 1);
		}
	}, [name, watch(name!), dependencyField]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	if (!name) return null;

	return (
		<Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<InputLabel>{label}</InputLabel>
			<FormControl fullWidth>
				<Controller
					key={key}
					name={name}
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<Autocomplete
							{...field}
							id={name}
							componentsProps={{
								popper: {
									sx: {
										zIndex: 10,
									},
								},
							}}
							defaultValue={
								options.find((option) => option.value === value) || null
							}
							onChange={(_, data) => onChange(data ? data.value : "")}
							readOnly={!!dependencyField && !watch(dependencyField!)}
							fullWidth
							sx={{ "& fieldset": { borderRadius: "14px" } }}
							open={open}
							onOpen={() => setOpen(true)}
							onClose={() => setOpen(false)}
							getOptionLabel={(option) => option.text}
							options={options}
							loading={loading}
							loadingText="Cargando..."
							noOptionsText="Sin opciones."
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									error={!!get(errors, name)}
									helperText={!!get(errors, name) && get(errors, name).message}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loading ? <CircularProgress size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					)}
				/>
			</FormControl>
		</Grid>
	);
};
