import { Grid } from "@enerbit/base";
import { type ChangeEvent, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { useOrderStore } from "../../store";
//TO DO: Agregar buscador
export const SearchOrder = () => {
	const { setSearch, loadingOrders, debounceSearch, setDebounceSearch } =
		useOrderStore();
	const [debouncedValue] = useDebounce(debounceSearch, 500); // 500ms de debounce

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setDebounceSearch(event.target.value);
	};

	useEffect(() => {
		setSearch(debouncedValue);
	}, [debouncedValue, setSearch]);

	return (
		<Grid item xs={5}>
			{/* <FormControl fullWidth>
				<Grid container spacing={3}>
					<Grid item flex={1}>
						<TextField
							fullWidth
							name={"search"}
							value={debounceSearch}
							onChange={handleInputChange}
							sx={{ "& fieldset": { borderRadius: "14px" } }}
							type={"text"}
							id={"user-search"}
							disabled={loading}
							variant="outlined"
							placeholder="Buscar por nombre de operador"
							InputProps={{
								endAdornment: loading && (
									<InputAdornment position="end">
										<CircularProgress size={20} thickness={6} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			</FormControl> */}
		</Grid>
	);
};
