import {
	AddIcon,
	Autocomplete,
	Button,
	CircularProgress,
	DeleteOutlineIcon,
	FormControl,
	Grid,
	InputLabel,
	TextField,
	Typography,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import type { IItemsJson } from "../../interfaces";
import { getCities, getDepartments } from "../../services/getOptions";

type FormValues = {
	work_groups_coverage: Array<{
		department: string;
		cities: string[];
	}>;
};

export const DynamicLocationForm = () => {
	const {
		control,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext<FormValues>();

	const { fields, append, remove } = useFieldArray({
		control,
		name: "work_groups_coverage",
	});

	const [stateOptions, setStateOptions] = useState<IItemsJson[]>([]);
	const [cityOptions, setCityOptions] = useState<IItemsJson[][]>([]);
	const [isLoadingStates, setIsLoadingStates] = useState(false);
	const [isLoadingCities, setIsLoadingCities] = useState(false);

	// Cargar estados (departamentos)
	useEffect(() => {
		const loadStates = async () => {
			setIsLoadingStates(true);
			const states = await getDepartments();
			setIsLoadingStates(false);
			setStateOptions(states);
		};
		loadStates();
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const loadCitiesForAllDepartments = async () => {
			for (let i = 0; i < fields.length; i++) {
				const department = watch(`work_groups_coverage.${i}.department`);
				if (department) {
					await loadCities(department, i);
				}
			}
		};
		loadCitiesForAllDepartments();
	}, [stateOptions, fields, watch]);

	const loadCities = async (state: string, index: number) => {
		if (state) {
			const value = stateOptions.find(({ text }) => text === state)?.value;
			if (value) {
				setIsLoadingCities(true);
				const cities = await getCities(value.toString());
				setIsLoadingCities(false);
				setCityOptions((prev) => {
					const updatedCities = [...prev];
					updatedCities[index] = cities;
					return updatedCities;
				});
			}
		} else {
			setCityOptions((prev) => {
				const updatedCities = [...prev];
				updatedCities[index] = [];
				return updatedCities;
			});
		}
	};

	return (
		<Grid
			sx={{
				borderRadius: "14px",

				m: "auto",
				marginLeft: "24px",
				mt: 3,
			}}
			container
		>
			<Typography color="primary" variant="h6" my={2}>
				Cobertura de la cuadrilla
			</Typography>

			{fields.map((item, index) => (
				<Grid
					key={item.id}
					container
					sx={{
						border: "1px solid #A3A9B6",
						borderRadius: "14px",
						p: 2,
						m: "auto",
						mb: 2,
						position: "relative",
					}}
				>
					<Grid item xs={12} sx={{ mb: 2 }}>
						<InputLabel>Departamento</InputLabel>
						<FormControl sx={{ width: "85%" }}>
							<Controller
								name={`work_groups_coverage.${index}.department`}
								control={control}
								render={({ field: { onChange, ...field } }) => (
									<Autocomplete
										{...field}
										id={`coverage-department-${index}`}
										fullWidth
										sx={{ "& fieldset": { borderRadius: "14px" } }}
										options={stateOptions}
										getOptionLabel={(option) => option.text}
										loading={isLoadingStates}
										loadingText="Cargando..."
										noOptionsText="Sin opciones."
										onChange={(_, value) => {
											onChange(value ? value.text : "");
											loadCities(value?.text ?? "", index);
											setValue(`work_groups_coverage.${index}.cities`, []);
										}}
										value={
											stateOptions.find(
												(option) =>
													option.text ===
													watch(`work_groups_coverage.${index}.department`),
											) || null
										}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												error={
													!!errors?.work_groups_coverage?.[index]?.department
												}
												helperText={
													errors?.work_groups_coverage?.[index]?.department
														?.message
												}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<>
															{isLoadingStates ? (
																<CircularProgress size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</>
													),
												}}
											/>
										)}
									/>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<InputLabel>Ciudades</InputLabel>
						<FormControl sx={{ width: "85%" }}>
							<Controller
								name={`work_groups_coverage.${index}.cities`}
								control={control}
								render={({ field: { onChange, value, ...field } }) => (
									<Autocomplete
										{...field}
										id={`coverage-cities-${index}`}
										disabled={
											!watch(`work_groups_coverage.${index}.department`)
										}
										fullWidth
										multiple
										sx={{ "& fieldset": { borderRadius: "14px" } }}
										options={cityOptions[index] || []}
										getOptionLabel={(option) => option.text}
										loading={isLoadingCities}
										loadingText="Cargando..."
										noOptionsText="Sin opciones."
										onChange={(_, selectedOptions) => {
											onChange(selectedOptions.map((opt) => opt.text));
										}}
										value={(cityOptions[index] || []).filter((option) =>
											value?.includes(option.text),
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												error={!!errors?.work_groups_coverage?.[index]?.cities}
												helperText={
													errors?.work_groups_coverage?.[index]?.cities?.message
												}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<>
															{isLoadingCities ? (
																<CircularProgress size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</>
													),
												}}
											/>
										)}
									/>
								)}
							/>
						</FormControl>
					</Grid>

					<Button
						size="small"
						variant="outlined"
						color="error"
						onClick={() => remove(index)}
						sx={{
							mt: 2,
							position: "absolute",
							right: "20px",
							top: "5px",
							height: "45px",
							width: "40px",
							border: "1px solid red",
						}}
					>
						<DeleteOutlineIcon />
					</Button>
				</Grid>
			))}

			<Button
				size="small"
				variant="contained"
				color="secondary"
				startIcon={<AddIcon fontSize="small" />}
				sx={{ my: 2 }}
				onClick={() => append({ department: "", cities: [] })}
			>
				Nueva cobertura
			</Button>
		</Grid>
	);
};
