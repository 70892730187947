import { api } from "@enerbit/base";
import type { IWorkGroupsItem } from "../interfaces";

export const getTeamsByCompany = async (
	company_id: string,
): Promise<IWorkGroupsItem[]> => {
	const pageSize = 50;
	let page = 0;
	let allTeams: IWorkGroupsItem[] = [];
	let hasMore = true;

	try {
		while (hasMore) {
			const { data } = await api.get<{
				items: IWorkGroupsItem[];
				total: number;
			}>(
				`/work-groups/work-groups?company_id=${company_id}&page=${page}&size=${pageSize}`,
			);

			allTeams = [...allTeams, ...data.items];
			page++;
			hasMore = allTeams.length < data.total;
		}

		return allTeams;
	} catch (error) {
		console.error("Error fetching teams by company:", error);
		throw new Error("Error fetching teams by company");
	}
};

export const getCompanyById = async (id: string) => {
	const { data } = await api.get(`/ops-users/companies/${id}/`);
	return data.name;
};
