import {
	Box,
	Button,
	Grid,
	IconButton,
	LinearProgress,
	Stack,
	Typography,
	enerbitColors,
	useSnackbar,
} from "@enerbit/base";
import CheckIcon from "@mui/icons-material/Check";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UpdateIcon from "@mui/icons-material/Update";
import { type FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { get, useFormContext } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";
import { loadFile, uploadFile } from "../../../services";

export const FileForm: FC<IDynamicForm> = ({ name, label }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState<number>(0);

	const { enqueueSnackbar } = useSnackbar();
	const {
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	const handleOpenPreviewFile = async () => {
		await loadFile(watch(name!))
			.then((pdf) => {
				console.log(pdf);
			})
			.catch(() =>
				enqueueSnackbar("No se pudo cargar el archivo PDF.", {
					variant: "error",
				}),
			);
	};

	const handleFileChange = async (file: File) => {
		if (file) {
			try {
				setIsLoading(true);
				setProgress(0);
				const pdfUrl = await uploadFile(file, (event: ProgressEvent) => {
					const percentCompleted = Math.round(
						(event.loaded * 100) / event.total,
					);
					setProgress(percentCompleted);
				});
				setValue(name!, pdfUrl);
				setIsLoading(false);
			} catch (error) {
				console.error("Error uploading PDF:", error);
			}
		}
	};

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			handleFileChange(acceptedFiles[0]);
		},
		[handleFileChange],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"application/pdf": [],
		},
	});

	return (
		<Grid item xs={12}>
			{watch(name!) || (!watch(name!) && isLoading) ? (
				<Stack spacing={3} direction={"row"} sx={{ width: "100%" }}>
					<Box className="pdf">PDF</Box>
					<Box sx={{ flex: 1 }}>
						<Typography
							sx={{
								fontSize: "18px",
								fontWeight: "bold",
								color: "#14161B",
							}}
						>
							{label}
						</Typography>
						{isLoading && (
							<LinearProgress variant="determinate" value={progress} />
						)}
					</Box>
					<Stack direction={"row"} spacing={3} padding={1}>
						<IconButton onClick={handleOpenPreviewFile}>
							<RemoveRedEyeIcon />
						</IconButton>
						<IconButton
							aria-label="delete"
							onClick={() => setValue(name!, "")}
							color={!!get(errors, name) ? "error" : "success"}
							sx={{
								borderRadius: "50%",
								backgroundColor: !!get(errors, name)
									? enerbitColors.error[100]
									: enerbitColors.success[100],
							}}
						>
							{!!get(errors, name) ? (
								<UpdateIcon sx={{ color: enerbitColors.error.main }} />
							) : (
								<CheckIcon sx={{ color: enerbitColors.success.main }} />
							)}
						</IconButton>
					</Stack>
				</Stack>
			) : (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Box
							{...getRootProps()}
							className="dropzone"
							sx={{
								border: `2px dashed ${
									!!get(errors, name) ? enerbitColors.error.main : "#cccccc"
								}`,
								borderRadius: "12px",
								p: 4,
								textAlign: "center",
								cursor: "pointer",
								backgroundColor: isDragActive ? "#eeeeee" : "",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								gap: 2,
							}}
						>
							<input {...getInputProps()} />
							<Typography>
								{isDragActive ? "Suelta el archivo aquí" : "Arrastra aquí o"}
							</Typography>
							<Button variant="contained" sx={{ mt: 2 }}>
								Adjunte el archivo
							</Button>
						</Box>
						{!!get(errors, name) && (
							<Typography color="error" fontSize={"0.75rem"}>
								{get(errors, name).message}
							</Typography>
						)}
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
