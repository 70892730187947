import { OptionOperator } from "../components/options";
import type { IDynamicForm } from "../interfaces";
import { getOperators } from "../services/getOptions";

export const formTeam: IDynamicForm[] = [
	{
		label: "Operadores en campo para esta cuadrilla",
		name: "operator_ids",
		optionLabel: "username",
		type: "custom-autocomplete",
		fetchItems: (param?: string) => getOperators(param ?? ""),
		renderItem: OptionOperator,
		sx: {
			xs: 12,
		},
	},
	{
		label: "Horario semanal",
		name: "schedules",
		// optionLabel: "username",
		type: "schedules-week",
		// fetchItems: (param?: string) => getOperators(param ?? ""),
		// renderItem: OptionOperator,
		sx: {
			xs: 12,
		},
	},
	// {
	// 	name: "schedules.week",
	// 	type: "schedule",
	// 	label: "Horario semanal ordinario",
	// 	items: [
	// 		{
	// 			text: "Lunes",
	// 			value: "monday",
	// 		},
	// 		{
	// 			text: "Martes",
	// 			value: "tuesday",
	// 		},
	// 		{
	// 			text: "Miércoles",
	// 			value: "wednesday",
	// 		},
	// 		{
	// 			text: "Jueves",
	// 			value: "thursday",
	// 		},
	// 		{
	// 			text: "Viernes",
	// 			value: "friday",
	// 		},
	// 	],
	// },
	// {
	// 	type: "switch",
	// 	name: "schedules.weekend",
	// 	label: "¿Cuenta con horario de fin de semana?",
	// 	json: {
	// 		saturday: null,
	// 		sunday: null,
	// 	},
	// 	children: [
	// 		{
	// 			type: "schedule",
	// 			label: "Horario fin de semana ordinario",
	// 			items: [
	// 				{
	// 					text: "Sábado",
	// 					value: "saturday",
	// 				},
	// 				{
	// 					text: "Domingo",
	// 					value: "sunday",
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	// 	type: "switch",
	// 	name: "schedules.overtime",
	// 	label: "¿Puede ejecutar actividades en horario extraordinario?",
	// 	json: {
	// 		monday: null,
	// 		tuesday: null,
	// 		wednesday: null,
	// 		thursday: null,
	// 		friday: null,
	// 		saturday: null,
	// 		sunday: null,
	// 	},
	// 	children: [
	// 		{
	// 			type: "schedule",
	// 			label: "horario extraordinario",
	// 			items: [
	// 				{
	// 					text: "Lunes",
	// 					value: "monday",
	// 				},
	// 				{
	// 					text: "Martes",
	// 					value: "tuesday",
	// 				},
	// 				{
	// 					text: "Miércoles",
	// 					value: "wednesday",
	// 				},
	// 				{
	// 					text: "Jueves",
	// 					value: "thursday",
	// 				},
	// 				{
	// 					text: "Viernes",
	// 					value: "friday",
	// 				},
	// 				{
	// 					text: "Sábado",
	// 					value: "saturday",
	// 				},
	// 				{
	// 					text: "Domingo",
	// 					value: "sunday",
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	{
		name: "mobility",
		type: "checkbox-group",
		title: "Seleccione el tipo de vehículo",
		label: "",
		items: [
			{
				text: "Carro",
				value: "carro",
			},
			{
				text: "Moto",
				value: "moto",
			},
		],
	},
	{
		type: "date",
		name: "ended_at",
		label: "Fecha de vencimiento de esta cuadrilla",

		sx: {
			xs: 12,
		},
		props: {
			disablePast: true,
			disableToday: true,
		},
	},
];
