import { dayjs } from "@enerbit/base";
import { ZodIssueCode, z } from "zod";

export const getInitialMinDate = () => {
	return dayjs();
};

export const getFinalMinDate = (initialDate: Date) => {
	return dayjs(initialDate).add(1, "minute");
};

export const getFinalMaxDate = (initialDate: Date) => {
	return dayjs(initialDate);
};

export const getFinalMaxTime = (initialDate: string, finalDate: string) => {
	return dayjs(initialDate).isSame(dayjs(finalDate), "day")
		? undefined
		: dayjs(initialDate).add(24, "hour");
};

export const getInitialMinTime = (initialDate: string) => {
	return dayjs(initialDate).isSame(dayjs(), "day") ? dayjs() : undefined;
};

export const getFinalMinTime = (initialDate: Date, finalDate: Date) => {
	return dayjs(initialDate).isSame(dayjs(finalDate), "day")
		? dayjs(initialDate).add(1, "minute")
		: undefined;
};

export const initialDateValidation = (value: string, ctx: z.RefinementCtx) => {
	const minDate = getInitialMinDate();
	const date = dayjs(value);

	if (date.isBefore(minDate)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `La fecha debe ser posterior a ${minDate.format(
				"YYYY-MM-DD HH:mm:ss",
			)}`,
		});
		return false;
	}

	const minTime = getInitialMinTime(value);
	if (minTime && date.hour() < minTime.hour()) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `La hora inicial debe estar a partir de ${minTime.hour()}:00`,
		});
		return false;
	}

	return true;
};

export const finalDateValidation = (
	begin: Date,
	end: Date,
	ctx: z.RefinementCtx,
) => {
	const minDate = getFinalMinDate(begin);
	const date = dayjs(end);

	if (date.isBefore(minDate)) {
		ctx.addIssue({
			path: ["planned_date_end"],
			code: z.ZodIssueCode.custom,
			message: `La fecha debe ser posterior a ${minDate.format(
				"YYYY-MM-DD HH:mm:ss",
			)}`,
			fatal: true,
		});
	}

	const minTime = getFinalMinTime(begin, end);
	if (minTime && date.isBefore(minTime)) {
		ctx.addIssue({
			path: ["planned_date_end"],
			code: z.ZodIssueCode.custom,
			message: `La hora final debe ser posterior a ${minTime.hour()}:00`,
			fatal: true,
		});
	}
};
