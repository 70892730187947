import {
	ArrowCircleLeftOutlinedIcon,
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	Typography,
	useSnackbar,
} from "@enerbit/base";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FiltersOrder, SearchOrder } from "../components/order";
import { useOrderStore } from "../store";
import "./Crono.css";
import DynamicCalendar from "../components/Calendar/DynamicCalendar";

export const Crono = () => {
	const { message } = useOrderStore();

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (message) {
			enqueueSnackbar(message.description, { variant: message.status });
		}
	}, [message]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Stack spacing={3} direction="row" alignItems={"center"}>
						<Link to={"/users-companies"}>
							<IconButton
								color="primary"
								size="small"
								sx={{ p: 0 }}
								id="back-chrono"
							>
								<ArrowCircleLeftOutlinedIcon fontSize="large" />
							</IconButton>
						</Link>
						<Typography
							color="primary"
							sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
						>
							Tablero Crono
						</Typography>
					</Stack>
					<Link to="/crono/teams">
						<Button color="secondary" variant="contained" id="teams">
							Ver cuadrillas
						</Button>
					</Link>
				</Box>
			</Grid>
			<SearchOrder />
			<FiltersOrder />
			<DynamicCalendar />
		</Grid>
	);
};
