import { Box, Grid, decodedJwt, useSnackbar } from "@enerbit/base";
import { useEffect } from "react";
import { PaginationTeam, TeamItem, TeamItemSkeleton } from ".";
import { useTeamStore } from "../../store";

export const ListTeam = () => {
	const { getTeams, listTeams, loading, departments, search } = useTeamStore();
	const { page, size } = useTeamStore((state) => state.pagination);
	const { selectedState, selectedCity, selectedMeter } = useTeamStore(
		(state) => state.filters,
	);
	const decodedToken = decodedJwt();

	const role = decodedToken?.role;
	const company_id = decodedToken?.company_id;

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const fetchTeams = async () => {
			if (role) {
				try {
					const state = departments.find(
						({ value }) => value === selectedState[0],
					)?.text;
					await getTeams(
						page,
						size,
						state,
						selectedCity[0],
						selectedMeter.join(","),
						search,
						role === "contratista" ? company_id : undefined,
					);
				} catch (error) {
					enqueueSnackbar((error as Error).message, { variant: "error" });
				}
			}
		};

		fetchTeams();
	}, [
		page,
		size,
		selectedState,
		selectedCity,
		selectedMeter,
		search,
		company_id,
		role,
	]);

	return (
		<Grid item xs={12}>
			<Box sx={{ display: "flex", gap: 3, flexDirection: "column" }}>
				<Grid container spacing={3}>
					{loading ? (
						<>
							{Array.from(new Array(8)).map((_, index) => {
								return <TeamItemSkeleton key={index.toString()} />;
							})}
						</>
					) : (
						<>
							{listTeams.map((item, index) => {
								return (
									<TeamItem
										item={item}
										index={index.toString()}
										key={index.toString()}
									/>
								);
							})}
						</>
					)}
				</Grid>
				<PaginationTeam />
			</Box>
		</Grid>
	);
};
