import type { ComponentType } from "react";
import type { IControlType, IDynamicForm } from "../../interfaces";
import SchedulesTeam from "../Teams/SchedulesTeam";
import { AccordionForm } from "./items/AccordionForm";
import { ArrayForm } from "./items/ArrayForm";
import { AutocompleteForm } from "./items/AutocompleteForm";
import { CheckboxGroupForm } from "./items/CheckboxGroupForm";
import { CustomAutocompleteForm } from "./items/CustomAutocompleteForm";
import { DateForm } from "./items/DateForm";
import { DateTimeForm } from "./items/DateTimeForm";
import { FileForm } from "./items/FileForm";
import { ImageForm } from "./items/ImageForm";
import { ProfileImageForm } from "./items/ProfileImageForm";
import { ScheduleForm } from "./items/ScheduleForm";
import { SelectForm } from "./items/SelectForm";
import { SwitchForm } from "./items/SwitchForm";
import { TextForm } from "./items/TextForm";
import { TimeForm } from "./items/TimeForm";
import { TitleForm } from "./items/TitleForm";

export const ComponentMap: Record<IControlType, ComponentType<IDynamicForm>> = {
	text: TextForm,
	email: TextForm,
	number: TextForm,
	"text-area": TextForm,
	password: TextForm,
	select: SelectForm,
	autocomplete: AutocompleteForm,
	"custom-autocomplete": CustomAutocompleteForm,
	image: ImageForm,
	file: FileForm,
	date: DateForm,
	"date-time": DateTimeForm,
	time: TimeForm,
	switch: SwitchForm,
	array: ArrayForm,
	title: TitleForm,
	profile: ProfileImageForm,
	accordion: AccordionForm,
	"checkbox-group": CheckboxGroupForm,
	schedule: ScheduleForm,
	"schedules-week": SchedulesTeam,
};
