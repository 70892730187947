import { api } from "@enerbit/base";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type {
	IHistory,
	ILeadItem,
	IRespOutputData,
	IResponseLeads,
	ISelectedOrderState,
} from "../interfaces";

export const useSelectedOrderStore = create<ISelectedOrderState>()(
	devtools(
		(set) => ({
			loadingInfoOrder: false,
			leadsOrder: [],
			selectedOrder: null,
			previous_diagnostic: null,
			loadingDiagnostic: false,
			responsible: "",
			observation: "",
			handleOpenOrder: (order) => set({ selectedOrder: order }),
			handleCloseOrder: () => set({ selectedOrder: null }),
			getInfoOrder: async (leads_ids, id) => {
				try {
					set({ loadingInfoOrder: true });

					const leadsPromises = leads_ids.map((leadId) =>
						api.get<ILeadItem>(`assignees/leads/${leadId}`),
					);
					const leadsResponses = await Promise.all(leadsPromises);
					const leads = leadsResponses.map((response) => response.data);

					const historyResponse = await api.get<IHistory>(
						`service-orders/order-historical?order_id=${id}&page=0&size=50&event=created`,
					);

					const lastIndex = historyResponse.data.items.length - 1;
					const responsible =
						historyResponse.data.items[lastIndex].responsible.names;

					const observation =
						historyResponse.data.items[lastIndex]?.change_data?.body?.form_data
							?.description;

					set({
						leadsOrder: leads,
						responsible,
						loadingInfoOrder: false,
						observation,
					});
				} catch (error) {
					console.log(error);
					set({ loadingInfoOrder: false });
				}
			},
			getOutPutData: async (id, is_lead) => {
				set({ previous_diagnostic: null, loadingInfoOrder: true });
				try {
					let leadId = id;

					if (!is_lead) {
						const responseLeads = await api.get<IResponseLeads>(
							`/assignees/leads?measurement_point_id=${id}`,
						);
						leadId = responseLeads.data.items[0]?.id;

						if (!leadId) {
							throw new Error("No lead found");
						}
					}

					const outputDataResponse = await api.get<IRespOutputData>(
						`/frontier-initial-diagnostic/output-data?lead_id=${leadId}&is_field=true`,
					);

					set({
						previous_diagnostic: outputDataResponse.data.previous_diagnostic,
						loadingInfoOrder: false,
					});
				} catch (error: any) {
					set({ previous_diagnostic: null, loadingInfoOrder: false });
					if (error.response && error.response.status === 404) {
						console.log("Output data not found (404), continuing...");
					} else {
						throw error;
					}
				}
			},
		}),
		{ anonymousActionType: "selectedOrder" },
	),
);
