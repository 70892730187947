import { type Dayjs, dayjs } from "@enerbit/base";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import { z } from "zod";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const timeRangesOverlap = (
	range1: { since: string; until: string },
	range2: { since: string; until: string },
): { startOverlap: boolean; endOverlap: boolean } => {
	const start1 = dayjs(range1.since).utc().format("HH:mm");
	const end1 = dayjs(range1.until).utc().format("HH:mm");
	const start2 = dayjs(range2.since).utc().format("HH:mm");
	const end2 = dayjs(range2.until).utc().format("HH:mm");

	return {
		startOverlap:
			dayjs(start1, "HH:mm").isBefore(dayjs(end2, "HH:mm")) &&
			dayjs(start1, "HH:mm").isSameOrAfter(dayjs(start2, "HH:mm")),
		endOverlap:
			dayjs(end1, "HH:mm").isSameOrBefore(dayjs(end2, "HH:mm")) &&
			dayjs(end1, "HH:mm").isAfter(dayjs(start2, "HH:mm")),
	};
};

const isDate = (dateString: string | null): boolean => {
	if (dateString === null) return false;
	const date = new Date(dateString);
	return !Number.isNaN(date.getTime());
};

const transformDayjsToString = (val: Dayjs | string | null): string | null => {
	if (val === null) return null;
	if (dayjs.isDayjs(val)) {
		return val.format("HH:mm:ss.00[Z]");
	}
	return val;
};

const BaseRangeSchema = z.object({
	since: z
		.custom<Dayjs>((val) => dayjs.isDayjs(val) || typeof val === "string")
		.nullable()
		.transform(transformDayjsToString)
		.refine((val): val is string => val !== null && val.length > 0, {
			message: "Este campo es obligatorio.",
		}),
	until: z
		.custom<Dayjs>((val) => dayjs.isDayjs(val) || typeof val === "string")
		.nullable()
		.transform(transformDayjsToString)
		.refine((val): val is string => val !== null && val.length > 0, {
			message: "Este campo es obligatorio.",
		}),
	is_overtime: z.boolean().optional(),
});

export const RangeSchema = BaseRangeSchema.superRefine(
	({ since, until }, ctx) => {
		if (since && until) {
			const currentDate = dayjs().startOf("day");

			let sinceDate = dayjs(since, "HH:mm:ss");
			let untilDate = dayjs(until, "HH:mm:ss");

			sinceDate = sinceDate
				.set("year", currentDate.year())
				.set("month", currentDate.month())
				.set("date", currentDate.date());

			untilDate = untilDate
				.set("year", currentDate.year())
				.set("month", currentDate.month())
				.set("date", currentDate.date());

			if (untilDate.isBefore(sinceDate)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message:
						"La fecha de finalización no debe ser menor que la fecha inicio.",
					path: ["until"],
				});
			}
		}
	},
);

// const OvertimeSchema = BaseRangeSchema.extend({
// 	day: z.string().min(1, "Este campo es obligatorio."),
// }).superRefine(({ since, until }, ctx) => {
// 	if (since && until) {
// 		// Obtener el comienzo del día actual en UTC
// 		const currentDate = dayjs().startOf("day");

// 		// Convertir las horas a objetos dayjs en UTC y restar 5 horas
// 		let sinceDate = dayjs(since, "HH:mm:ss");
// 		let untilDate = dayjs(until, "HH:mm:ss");

// 		// Establecer el mismo día para ambos
// 		sinceDate = sinceDate
// 			.set("year", currentDate.year())
// 			.set("month", currentDate.month())
// 			.set("date", currentDate.date());

// 		untilDate = untilDate
// 			.set("year", currentDate.year())
// 			.set("month", currentDate.month())
// 			.set("date", currentDate.date());

// 		if (untilDate.isBefore(sinceDate)) {
// 			ctx.addIssue({
// 				code: z.ZodIssueCode.custom,
// 				message:
// 					"La fecha de finalización no debe ser menor que la fecha inicio.",
// 				path: ["until"],
// 			});
// 		}
// 	}
// });

const BaseTeamSchema = z.object({
	work_groups_coverage: z.array(
		z.object({
			department: z.string().min(1, { message: "Este campo es obligatorio" }),
			cities: z.array(z.any()).min(1, { message: "Este campo es obligatorio" }),
		}),
	),
	state: z.string().min(1, { message: "Este campo es obligatorio" }),
	city: z.string().min(1, { message: "Este campo es obligatorio" }),
	operator_ids: z
		.array(z.any())
		.nullable()
		.transform((value) => (value === null ? [] : value))
		.refine((val) => val !== null && val.length > 0, {
			message: "Este campo es obligatorio.",
		}),
	schedules: z.object({
		week: z.object({
			monday: z.array(RangeSchema).nullable(),
			tuesday: z.array(RangeSchema).nullable(),
			wednesday: z.array(RangeSchema).nullable(),
			thursday: z.array(RangeSchema).nullable(),
			friday: z.array(RangeSchema).nullable(),
			saturday: z.array(RangeSchema).nullable(),
			sunday: z.array(RangeSchema).nullable(),
		}),
		// weekend: z
		// 	.object({
		// 		saturday: z.array(RangeSchema).nullable(),
		// 		sunday: z.array(RangeSchema).nullable(),
		// 	})
		// 	.nullable(),
		// overtime: z
		// 	.object({
		// 		monday: z.array(RangeSchema).nullable(),
		// 		tuesday: z.array(RangeSchema).nullable(),
		// 		wednesday: z.array(RangeSchema).nullable(),
		// 		thursday: z.array(RangeSchema).nullable(),
		// 		friday: z.array(RangeSchema).nullable(),
		// 		saturday: z.array(RangeSchema).nullable(),
		// 		sunday: z.array(RangeSchema).nullable(),
		// 	})
		// 	.nullable(),
	}),
	ended_at: z
		.string()
		.nullable()
		.refine((val): val is string => val !== null && val.length > 0, {
			message: "Este campo es obligatorio.",
		})
		.refine((val): val is string => isDate(val), {
			message: "Fecha inválida.",
		}),
	mobility: z.array(z.string()),
});

export const CreateTeamSchema = BaseTeamSchema;

export const UpdateTeamSchema = BaseTeamSchema.extend({
	id: z.string().min(1, "Este campo es obligatorio para la actualización."),
}).superRefine(({ schedules }, ctx) => {
	if (schedules) {
		const normalRanges: { since: string; until: string }[] = [];

		for (const day of Object.values(schedules.week ?? {})) {
			if (day)
				normalRanges.push(
					...day.filter(
						(d): d is { since: string; until: string } => d !== null,
					),
				);
		}

		// for (const day of Object.values(schedules.weekend ?? {})) {
		// 	if (day)
		// 		normalRanges.push(
		// 			...day.filter(
		// 				(d): d is { since: string; until: string } => d !== null,
		// 			),
		// 		);
		// }

		// for (const day of Object.values(schedules.overtime ?? {})) {
		// 	if (day)
		// 		normalRanges.push(
		// 			...day.filter(
		// 				(d): d is { since: string; until: string } => d !== null,
		// 			),
		// 		);
		// }

		// for (const [overtimeIndex, overtime] of schedules.overtime?.entries() ??
		// 	[]) {
		// 	for (const normalRange of normalRanges) {
		// 		const overlap = timeRangesOverlap(overtime, normalRange);
		// 		if (overlap.startOverlap) {
		// 			ctx.addIssue({
		// 				code: z.ZodIssueCode.custom,
		// 				message: "El inicio se solapa con el horario ordinario.",
		// 				path: ["schedules", "overtime", overtimeIndex.toString(), "since"],
		// 			});
		// 		}
		// 		if (overlap.endOverlap) {
		// 			ctx.addIssue({
		// 				code: z.ZodIssueCode.custom,
		// 				message: "La finalización se solapa con el horario ordinario.",
		// 				path: ["schedules", "overtime", overtimeIndex.toString(), "until"],
		// 			});
		// 		}
		// 	}
		// }
	}
});
