import {
	FormControlLabel,
	Grid,
	InputLabel,
	Switch,
	enerbitColors,
} from "@enerbit/base";
import type { ChangeEvent, FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";
import { FormItem } from "../FormItem";

export const SwitchForm: FC<IDynamicForm> = ({
	name,
	label,
	sx,
	children,
	json,
}) => {
	const { control, setValue, watch } = useFormContext();

	const watchedValue = useWatch({ name: name!, control });

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;
		watch(name!);
		if (json && children) {
			if (checked) {
				setValue(name!, json);
			} else {
				// Elimina '.0' del final si existe y establece el valor en null
				const updatedName = name?.endsWith(".0") ? name.slice(0, -2) : name;
				setValue(updatedName!, null);
			}
		} else {
			setValue(name!, checked);
		}
	};

	if (!name) return null;

	return (
		<Grid container item spacing={3} xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<Grid item>
				<InputLabel
					sx={{
						display: "flex",
						alignItems: "end",
						color: enerbitColors.primary.main,
						fontWeight: "bold",
					}}
				>
					{label}
				</InputLabel>
				<FormControlLabel
					control={
						<Switch
							id={name}
							color="secondary"
							checked={!!watchedValue || false}
							onChange={handleChange}
						/>
					}
					label={watchedValue ? "Si" : "No"}
				/>
			</Grid>
			<Grid container item spacing={3}>
				{children &&
					watch(name) &&
					children?.map((item, i) => {
						const currentField = {
							...item,
							name: item.name ? `${name}.${item.name}` : `${name}`,
						};
						return <FormItem {...currentField} key={i.toString()} />;
					})}
			</Grid>
		</Grid>
	);
};
