import type { FC } from "react";
import type { IDynamicForm } from "../../interfaces";
import { ComponentMap } from "./ComponentMap";

interface FormItemProps extends IDynamicForm {
	isUpdate?: boolean;
}

export const FormItem: FC<FormItemProps> = ({ isUpdate, ...field }) => {
	const { type } = field;
	const Component = ComponentMap[type] as React.FC<FormItemProps>;

	return <Component {...field} isUpdate={isUpdate} />;
};
