import {
	AdapterDayjs,
	DateTimePicker,
	Grid,
	InputLabel,
	LocalizationProvider,
	dayjs,
} from "@enerbit/base";
import { type FC, useCallback, useMemo } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";
import { getInitialMinDate, getInitialMinTime } from "../../../utils";
import "dayjs/locale/es";

export const DateTimeForm: FC<IDynamicForm> = ({ label, name, sx, props }) => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
	} = useFormContext();
	const currentValue = name && watch(name);

	const shouldDisableDate = useCallback(
		(day: dayjs.Dayjs | null): boolean => {
			if (!day) return false;
			return props?.disableToday ? dayjs().isSame(day, "day") : false;
		},
		[props?.disableToday],
	);

	const calculateMinTime = useMemo(() => {
		if (props?.disablePast) {
			const nowIsoString = new Date().toISOString();
			const currentMinTime = getInitialMinTime(nowIsoString);
			const specificMinTime = props?.minTime ? dayjs(props.minTime) : undefined;

			if (currentMinTime && specificMinTime) {
				return currentMinTime.isAfter(specificMinTime)
					? currentMinTime
					: specificMinTime;
			}
			return currentMinTime || specificMinTime;
		}
		return undefined;
	}, [props?.disablePast, props?.minTime]);

	const calculatedMinDate = useMemo(() => {
		const disablePastDate = props?.disablePast
			? getInitialMinDate()
			: undefined;
		const specificMinDate = props?.minDate ? dayjs(props.minDate) : undefined; // Convierte a Dayjs o deja como undefined

		if (!disablePastDate && !specificMinDate) return undefined;

		return disablePastDate && specificMinDate
			? specificMinDate.isAfter(disablePastDate)
				? specificMinDate
				: disablePastDate
			: disablePastDate || specificMinDate;
	}, [props?.disablePast, props?.minDate]);

	const handleDateTimeChange = (date: dayjs.Dayjs | null) => {
		if (name) {
			const dateStr = date?.isValid() ? date.toISOString() : null;
			setValue(name, dateStr);
		}
	};

	if (!name) return null;

	return (
		<Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<InputLabel className="title-input-edition">{label}</InputLabel>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
				<Controller
					name={name}
					control={control}
					defaultValue={null}
					render={({ field: { value, ...field } }) => (
						<DateTimePicker
							{...field}
							ampm
							disabled={props?.disabled}
							value={value ? dayjs(value) : null}
							key={currentValue || "empty"}
							format="YYYY/MM/DD hh:mm a"
							disablePast={props?.disablePast}
							disableFuture={props?.disableFuture}
							maxDate={props?.maxDate ? props.maxDate : undefined}
							minDate={calculatedMinDate}
							minTime={calculateMinTime}
							shouldDisableDate={shouldDisableDate}
							onChange={handleDateTimeChange}
							slotProps={{
								textField: {
									id: name,
									color: "primary",
									helperText: get(errors, name)
										? get(errors, name).message
										: "",
									error: !!get(errors, name),
									sx: {
										"& fieldset": { borderRadius: "14px" },
										"& p": { color: "red" },
										width: "100%",
									},
								},
							}}
						/>
					)}
				/>
			</LocalizationProvider>
		</Grid>
	);
};
