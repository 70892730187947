import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	LocationOnOutlinedIcon,
	PersonIcon,
	Typography,
	WarningIcon,
	decodedJwt,
} from "@enerbit/base";
import CommentIcon from "@mui/icons-material/Comment";
import DescriptionIcon from "@mui/icons-material/Description";
import { useEffect, useState } from "react";
import { DiagnosticViability } from ".";
import { useSelectedOrderStore } from "../../store/selectedOrder";

const InfoOrder = () => {
	const [showMore, setShowMore] = useState(false);
	const {
		leadsOrder,
		selectedOrder,
		previous_diagnostic,
		getInfoOrder,
		loadingInfoOrder,
		responsible,
		observation,
	} = useSelectedOrderStore();

	const decodedToken = decodedJwt();

	const role = decodedToken?.role;

	if (!selectedOrder) return null;

	const { id, state, city, order_entities, order_type } =
		selectedOrder.resource;

	const handleShowMore = () => {
		setShowMore(!showMore);
	};

	const maxLines = 3;
	const linesToShow = showMore ? leadsOrder?.length ?? 0 : maxLines;

	useEffect(() => {
		const fetchLeads = async () => {
			getInfoOrder(
				order_entities.map(({ assignee_id }) => assignee_id),
				id,
			);
		};

		fetchLeads();
	}, [order_entities]);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
			{loadingInfoOrder ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "500px",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					{role !== "contratista" && order_type.code !== "IPCCE" && (
						<>
							{previous_diagnostic ? (
								<DiagnosticViability />
							) : (
								<Box
									sx={{
										borderRadius: "12px",
										backgroundColor: "#EEEBF4",
										display: "flex",
										justifyContent: "space-between",
										px: 4,
										py: 2,
									}}
								>
									<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
										<WarningIcon color={"warning"} sx={{ fontSize: "18px" }} />
										<Typography
											sx={{
												fontWeight: "bold",
											}}
										>
											Aún no existe un diagnóstico de viabilidad.
										</Typography>
									</Box>
								</Box>
							)}
						</>
					)}
					<Typography
						color="primary"
						sx={{ fontSize: "18px" }}
						fontWeight={"bold"}
					>
						Información de la orden
					</Typography>
					<List dense>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<PersonIcon />
								</ListItemIcon>
								<ListItemText
									primary={
										<>
											<b>ID de la orden: </b>
											{selectedOrder.resource.id}
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
						{order_entities.length > 0 && (
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<DescriptionIcon />
									</ListItemIcon>
									<ListItemText
										primary={
											<>
												<b>Entidad: </b>
												{order_entities[0].entity.name}
											</>
										}
									/>
								</ListItemButton>
							</ListItem>
						)}
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<PersonIcon />
								</ListItemIcon>
								<ListItemText
									primary={
										<>
											<b>Creada por: </b>
											{responsible}
										</>
									}
								/>
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<LocationOnOutlinedIcon />
								</ListItemIcon>
								<ListItemText
									primary={
										<>
											{city} - {state}
										</>
									}
								/>
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<CommentIcon />
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography>
											<b>Observaciones: </b>
											{observation}
										</Typography>
									}
								/>
							</ListItemButton>
						</ListItem>
					</List>

					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography
								color="primary"
								sx={{ fontSize: "18px" }}
								fontWeight={"bold"}
							>
								Información clientes
							</Typography>
							<List dense>
								{leadsOrder?.slice(0, linesToShow).map((entity, index) => {
									return (
										<ListItem disablePadding key={index.toString()}>
											<ListItemButton>
												<ListItemText
													primary={
														<Box key={index.toString()}>
															<Box
																sx={{
																	display: "flex",
																	gap: 2,
																	placeItems: "center",
																	width: "100%",
																}}
															>
																<Typography
																	variant="body1"
																	fontWeight={"bold"}
																	sx={{ fontSize: "16px" }}
																>
																	{entity.display_name}
																</Typography>
															</Box>
															<Box
																sx={{ display: "flex", gap: 2, width: "100%" }}
															>
																<Typography variant="body2">
																	{entity.email_from}
																</Typography>
																<Divider orientation="vertical" flexItem />
																<Typography variant="body2">
																	{" "}
																	{entity.address}
																</Typography>
															</Box>
														</Box>
													}
												/>
											</ListItemButton>
										</ListItem>
									);
								})}
							</List>
							{leadsOrder && leadsOrder.length > maxLines && (
								<Box sx={{ display: "flex", justifyContent: "center" }}>
									<Button
										onClick={handleShowMore}
										variant="text"
										color="secondary"
									>
										{showMore ? "Ocultar" : "Ver todo"}
									</Button>
								</Box>
							)}
						</Grid>
					</Grid>
				</>
			)}
		</Box>
	);
};

export default InfoOrder;
