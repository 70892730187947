import { Grid, InputLabel, TextField } from "@enerbit/base";
import type { FC } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";

export const TextForm: FC<IDynamicForm> = ({
	name,
	label,
	type,
	sx,
	props,
}) => {
	const {
		control,
		formState: { errors },
		watch,
	} = useFormContext();

	const currentValue = watch(name!);

	return (
		<Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<InputLabel className="title-input-edition">{label}</InputLabel>
			<Controller
				name={name!}
				control={control}
				defaultValue={""}
				render={({ field: { onChange, value, ...field } }) => (
					<TextField
						fullWidth
						{...field}
						value={currentValue ?? ""}
						onChange={(e) =>
							onChange(
								type === "number" ? Number(e.target.value) : e.target.value,
							)
						}
						sx={{ "& fieldset": { borderRadius: "14px" } }}
						variant="outlined"
						type={type === "text-area" ? "text" : type}
						multiline={type === "text-area"}
						rows={type === "text-area" ? "3" : undefined}
						InputProps={{ inputProps: { min: props?.min } }}
						error={!!get(errors, name)}
						helperText={!!get(errors, name) && get(errors, name).message}
					/>
				)}
			/>
		</Grid>
	);
};
