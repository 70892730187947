import type { ICoordination } from "../interfaces";

export const coordination: ICoordination[] = [
	{
		code: "INST_SOLAR",
		name: "Instalación para monitoreo solar",
		coordinations: [
			{ value: "customer", text: "Coordinado con el cliente" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "INS_SOLAR",
		name: "Inspección previa para monitoreo solar",
		coordinations: [{ value: "customer", text: "Coordinado con el cliente" }],
	},
	{
		code: "AIV",
		name: "Acompañamiento e inspección visual",
		coordinations: [{ value: "customer", text: "Coordinado con el cliente" }],
	},
	{
		code: "AEMERG",
		name: "Normalización atención de emergencias",
		coordinations: [{ value: "customer", text: "Coordinado con el cliente" }],
	},
	{
		code: "CCEG",
		name: "Cambio de comercializador a enerBit - Gabinete",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "CCE",
		name: "Cambio de comercializador a enerBit - Individual",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "ISBG",
		name: "Actualización / Cambio smartBit - Gabinete",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "CC",
		name: "Cancelación de cuenta",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "REMOV_CE_NO_INST",
		name: "Cancelación de cuenta sin instalación",
		coordinations: [{ value: "network_operator", text: "Coordinado con OR" }],
	},
	{
		code: "CSG",
		name: "Cesión de servicio - Gabinete",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "CS",
		name: "Cesión de servicio - Individual",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "DSM",
		name: "Atención cliente con afectación de servicio",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
		],
	},
	{
		code: "II",
		name: "Inspección de interventoria",
		coordinations: [{ value: "customer", text: "Cliente" }],
	},
	{
		code: "IG",
		name: "Inspección previa - Gabinete",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "IPCCE",
		name: "Inspección previa - Individual",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "IPP",
		name: "Inspección previa - Proyecto",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "ADCSM",
		name: "Verificación y/o actualización de datos del cliente",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "NT",
		name: "Normalización del sistema de comunicación",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "NSCN-RPE",
		name: "Normalización de servicio cliente notificado",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "NSG",
		name: "Nuevo servicio - Gabinete",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "NSDG",
		name: "Nuevo servicio desenergizado - Gabinete",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "NSDI",
		name: "Nuevo servicio desenergizado - Individual",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "NS",
		name: "Nuevo servicio - Individual",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "RSE",
		name: "Reconexión del servicio de energía",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{
				value: "network_operator",
				text: "Coordinado con OR/ No requiere coordinación con OR",
			},
		],
	},
	{
		code: "NCMP",
		name: "Normalización por código de medida o preventiva",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
			{ value: "pre_enlisted_materials", text: "Materiales prealistados" },
		],
	},
	{
		code: "RDCE",
		name: "Revisión por desviación del consumo de energía",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "SSSE",
		name: "Seguimiento de la suspensión del servicio de energía",
		coordinations: [],
	},
	{
		code: "SSE",
		name: "Suspensión del servicio de energía",
		coordinations: [],
	},
	{
		code: "RP",
		name: "Revisión por PQR",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "RPQR_SOLAR",
		name: "Revisión por PQR para monitoreo solar",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "VISITA_PRE",
		name: "Visita preliminar",
		coordinations: [],
	},
	{
		code: "NORM_SOLAR",
		name: "Normalización para monitoreo solar",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "CC_SOLAR",
		name: "Cancelación de cuenta sin instalación monitoreo solar",
		coordinations: [{ value: "customer", text: "Coordinado con cliente" }],
	},
	{
		code: "TECH_RECEIPT",
		name: "Recibo técnico",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
		],
	},
	{
		code: "NORM_CGM_THIRD",
		name: "Normalización CGM de terceros",
		coordinations: [
			{ value: "customer", text: "Coordinado con cliente" },
			{ value: "network_operator", text: "Coordinado con OR" },
		],
	},
];
