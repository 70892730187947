import { Box, Grid, Pagination } from "@enerbit/base";
import type { ChangeEvent } from "react";
import { useTeamStore } from "../../store";
import { EmptyData } from "../empty-data";

export const PaginationTeam = () => {
	const { listTeams, setPage, loading } = useTeamStore((state) => state);
	const { total, page, size } = useTeamStore((state) => state.pagination);

	const handlePageChange = (_: ChangeEvent<unknown>, value: number): void => {
		setPage(value - 1);
	};

	const totalPages = Math.ceil(total / size);

	const siblingCount = Math.max(1, Math.min(Math.floor(totalPages / 10), 3));
	const boundaryCount = Math.max(1, Math.min(Math.floor(totalPages / 15), 2));

	return (
		<Grid item xs={12}>
			{listTeams.length === 0 && !loading ? (
				<EmptyData message="No se encontraron" />
			) : (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Pagination
						id="pagination-team"
						disabled={loading}
						count={totalPages}
						page={page + 1}
						onChange={handlePageChange}
						shape="rounded"
						siblingCount={siblingCount}
						boundaryCount={boundaryCount}
					/>
				</Box>
			)}
		</Grid>
	);
};
