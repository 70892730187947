import { CachedIcon, Grid, IconButton, enerbitColors } from "@enerbit/base";
import { useEffect, useState } from "react";
import type { IItemsJson } from "../../interfaces";
import { getCities } from "../../services";
import { useTeamStore } from "../../store";
import { DynamicFilter } from "../dynamic/DynamicFIlter";

export enum ETeamCity {
	Bogota = "Bogota",
	Medellin = "Medellin",
}

export enum ETeamDepartment {
	Cundinamarca = "Cundinamarca",
	Antioquia = "Antioquia",
}

export enum EMeterType {
	Directa = "direct",
	Semidirecta = "semidirect",
	Indirecta = "indirect",
}

export const TeamFilters = () => {
	const { setDepartments, loading, search, setSearch, setDebounceSearch } =
		useTeamStore((state) => state);

	const {
		selectedState,
		selectedCity,
		selectedMeter,
		setSelectedState,
		setSelectedCity,
		setSelectedMeter,
	} = useTeamStore((state) => state.filters);

	const [optionsStates, setOptionsStates] = useState<IItemsJson[]>([]);
	const [optionsCities, setOptionsCities] = useState<IItemsJson[]>([]);

	const handleRefresh = () => {
		setSearch("");
		setDebounceSearch("");
		setSelectedCity([]);
		setSelectedMeter([]);
		setSelectedState([]);
	};

	useEffect(() => {
		const fetchDepartments = async () => {
			const departments = await setDepartments();
			setOptionsStates(departments);
		};

		fetchDepartments();
	}, []);

	useEffect(() => {
		const fetchCities = async () => {
			if (selectedState.length > 0) {
				const cities = await getCities(selectedState[0]);
				setOptionsCities(cities);
			}
		};

		fetchCities();
	}, [selectedState]);

	return (
		<Grid
			container
			item
			xs={6}
			spacing={3}
			sx={{ display: "flex", justifyContent: "end" }}
		>
			<Grid item>
				<DynamicFilter
					id="measurements"
					options={[
						{
							text: "Directa",
							value: "directa",
						},
						{
							text: "Semidirecta",
							value: "semidirecta",
						},
						{
							text: "Indirecta",
							value: "indirecta",
						},
					]}
					selected={selectedMeter}
					onSelect={setSelectedMeter}
					label="Tipo de medida"
					isMultiple
					disabled={loading}
				/>
			</Grid>
			<Grid item>
				<DynamicFilter
					id="state"
					options={optionsStates}
					selected={selectedState}
					onSelect={setSelectedState}
					label="Departamento"
					disabled={loading}
				/>
			</Grid>
			{selectedState.length > 0 && (
				<Grid item>
					<DynamicFilter
						id="city"
						options={optionsCities}
						selected={selectedCity}
						onSelect={setSelectedCity}
						label="Ciudad"
						disabled={loading}
					/>
				</Grid>
			)}
			<Grid item>
				<IconButton
					id="cache"
					onClick={handleRefresh}
					aria-label="cached"
					style={{
						color: "#fff",
						backgroundColor: enerbitColors.primary.main,
						borderRadius: "50%",
						padding: "10px",
					}}
				>
					<CachedIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};
