import { enerbitColors } from "@enerbit/base";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

export const StyledAccordion2 = styled(MuiAccordion)(({ theme }) => ({
	boxShadow: "none",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
	"&.Mui-expanded": {
		margin: "auto",
	},
	border: `1px solid ${enerbitColors.neutral[300]}`,
	borderRadius: "12px !important", // Radius from the image looks to be around 8px
}));

export const StyledAccordionSummary2 = styled(MuiAccordionSummary)(
	({ theme }) => ({
		minHeight: 56,
		"&.Mui-expanded": {
			minHeight: 56,
		},
		"& .MuiAccordionSummary-content": {
			margin: "10 !important", // Removes the additional margin when expanded
		},
		"& .MuiAccordionSummary-content.Mui-expanded": {
			margin: "10 !important",
		},
	}),
);

export const StyledAccordionDetails2 = styled(MuiAccordionDetails)(
	({ theme }) => ({
		flexDirection: "column",
		padding: theme.spacing(3),
		borderTop: `1px solid ${enerbitColors.neutral[100]}`,
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	}),
);
