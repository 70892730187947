import {
	ArrowCircleLeftOutlinedIcon,
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@enerbit/base";
import GroupsIcon from "@mui/icons-material/Groups";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
	CreateTeamForm,
	CreateTeamModal,
	ListTeam,
	SearchTeam,
	TeamFilters,
} from "../components/Teams";

export const Team = () => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Box
					sx={{ display: "flex", justifyContent: "space-between", gap: "20" }}
				>
					<Stack spacing={3} direction="row" alignItems={"center"}>
						{/* <Link to="/crono" style={{ textDecoration: "none" }}>
							<IconButton
								color="primary"
								size="small"
								sx={{ p: 0 }}
								id="back-team"
							>
								<ArrowCircleLeftOutlinedIcon fontSize="large" />
							</IconButton>
						</Link> */}
						<Typography
							color="primary"
							sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
						>
							Cuadrillas
						</Typography>
					</Stack>
					<Button
						id="add-team"
						onClick={handleOpen}
						color="secondary"
						variant="contained"
						startIcon={<GroupsIcon />}
					>
						Agregar cuadrilla
					</Button>
					<CreateTeamModal open={open} handleClose={handleClose} />
				</Box>
			</Grid>
			<SearchTeam />
			<TeamFilters />
			<ListTeam />
		</Grid>
	);
};
